import ClientAPI from "./ClientAPI";

export default class RepsService {
  async getReps(repsRequest: {
    ids?: string[];
    search?: string;
    active?: boolean;
    rangeStart?: number;
    rangeEnd?: number;
    fieldnames?: string;
    correls?: string;
  }) {
    const { ids, search, active, rangeStart, rangeEnd, fieldnames, correls } =
      repsRequest;
    return ClientAPI.Get(`reps`, {
      ids,
      search,
      active,
      rangeStart,
      rangeEnd,
      fieldnames,
      correls,
    });
  }
}
