import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import LocalDesktopAPI from "./LocalDesktopAPI";

export default class LocalDesktopService {
  constructor() {
    //this.client = axios.create({
    //  baseURL: serviceUrl,
    //  withCredentials: false,
    //});
  }

  async fetchHardwareRequest(supressErrorNotifications = false) {
    return LocalDesktopAPI.Get(
      `registerHardware/GetRegisterDetails`,
      "",
      supressErrorNotifications,
    );
  }

  async fetchStatus() {
    return LocalDesktopAPI.Get(`healthcheck`, "");
  }

  async printReceipt(receiptData: string, printerName: string) {
    return LocalDesktopAPI.Post(`print/printReceipt`, {
      encodedRequest: receiptData,
      printerName: printerName,
    });
  }

  async fetchPrinterList() {
    return LocalDesktopAPI.Get(`print/getPrinterList`, "");
  }
}
