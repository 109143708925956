import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";
export default class ContractService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getContracts(id: string, Client: string) {
    const params = {
      Client,
    };
    return ClientAPI.Get(`contract/${id}`, params);
  }
}
