import ApiService from "./ApiService";
import store from "../store";
import ClientAPI from "./ClientAPI";
import SalesOrder from "@/types/salesorder";

const ordersFile = "orders";
export default class SalesService extends ApiService {
  constructor() {
    super(ordersFile, [{ name: ordersFile, keyPath: "id" }]);
  }

  async getOrders(
    custId: string,
    ids: string,
    status: string,
    correls: string,
    date: string,
    filename?: string,
  ) {
    if (filename == undefined) filename = "so";
    const params = {
      custs: [custId],
      ids,
      status,
      correls,
      date,
      filename,
    };

    return ClientAPI.Post(`sales/orders/search`, params);
  }

  async getKpiInfo(cust: string, month: string, year: string) {
    const params = {
      cust,
      month,
      year,
    };
    return ClientAPI.Get(`sales/orders/kpi`, params);
  }

  async getOrderById(id: string, correls: string) {
    const params = {
      correls,
    };

    return ClientAPI.Get(`sales/orders/${id}`, params);
  }

  async getOrderPDF(id: string, client: string, email?: any) {
    let params;
    if (email) {
      params = {
        email: email.To,
        subject: email.Subject,
        body: email.Body,
        from: email.From,
        cc: email.Cc,
        bcc: email.Bcc,
        html: email.Html,
      };
    }
    return ClientAPI.Get(`sales/orders/${id}/pdf`, params);
  }
  async printPickTicket(id: string, client: string, printerName: string) {
    const params: any = {
      printerName: printerName,
      id: id,
      client: client,
    };
    return ClientAPI.Post(`sales/orders/printPickTicket`, params);
  }

  async getOrdersRecords(
    custId: any,
    ids: string,
    status: any,
    rangeStart: number,
    rangeEnd: number,
    sortBy: string,
    sortOrder: string,
    dateStart: string,
    dateEnd: string,
    correls: string,
    filename?: string,
    correl_criteria?: any,
    rep?: any,
    user?: string,
    type?: string[],
  ) {
    if (filename == undefined) filename = "SO";
    const params: any = {
      custs: custId,
      ids,
      status,
      sortBy,
      sortOrder,
      dateStart,
      dateEnd,
      rangeStart,
      rangeEnd,
      correls,
      filename,
      correl_criteria,
      rep,
      disableSortedSample: "Y",
      user,
      type,
    };

    params["rangeStart"] = rangeStart.toString();
    params["rangeEnd"] = rangeEnd.toString();

    return ClientAPI.Post(`sales/orders/search`, params);
  }

  async postOrder(
    payload: any,
    filename?: string,
    meta?: string,
    returnRecord?: string,
    entryUser?: string,
  ) {
    if (filename == undefined) filename = "SO";
    const user =
      entryUser || store.getters["session/getUser"].user_id || "WEBUSER";
    return ClientAPI.Post(
      `sales/orders?filename=${filename}&user=${user}&meta=${meta}&returnRecord=${returnRecord}`,
      payload,
    );
  }

  async putOrder(orderId: string, payload: any) {
    return ClientAPI.Put(`sales/orders/${orderId}`, payload);
  }

  async updateOrder(orderId: string, payload: any) {
    return ClientAPI.Put(`sales/orders/${orderId}`, payload);
  }

  saveDraftOrder(payload: any, filename?: string) {
    return this.dbStore?.putRecord(ordersFile, payload);
  }

  getDraftOrder(id: string) {
    return this.dbStore?.getRecord(ordersFile, id);
  }

  getDraftOrders(rangeStart?: number, rangeEnd?: number) {
    const cacheSearchFunction = (value: any) => {
      return true;
    };
    return this.dbStore?.searchRecords(
      ordersFile,
      cacheSearchFunction,
      rangeStart,
      rangeEnd,
    );
  }

  deleteDraftOrder(id: string) {
    return this.dbStore?.deleteRecord(ordersFile, id);
  }

  validateSalesOrder(validate: {
    newSalesOrder: SalesOrder;
    oldSalesOrder?: SalesOrder;
    user?: string;
    metaData?: string;
    returnRecord?: string;
  }) {
    const payload = {
      record: validate.newSalesOrder,
      oldRecord: validate.oldSalesOrder,
      user: validate.user,
      metaData: validate.metaData,
      returnRecord: validate.returnRecord,
    };
    return ClientAPI.Post(`sales/orders/validate`, payload);
  }
}
