import store from "@/store";
import { RouteLocationNormalizedLoaded } from "vue-router";

const inventoryRoutes = {
  path: "/inventory",
  name: "Purchase Orders",
  redirect: "/inventory/purchase-orders",
  component: () => import("../views/Inventory.vue"),
  children: [
    {
      name: "Purchase Orders",
      path: "/inventory/purchase-orders",
      component: () => import("../components/Inventory/PurchaseOrders.vue"),
      props: true,
      beforeEnter: (
        to: RouteLocationNormalizedLoaded,
        from: RouteLocationNormalizedLoaded,
        next: () => void,
      ): void => {
        resetTab(from);
        next();
      },
    },
    {
      name: "Receipts By ID",
      path: "/inventory/receipts/:receiptsId",
      component: (): Promise<unknown> =>
        import("@/components/Inventory/Receipts.vue"),
      props: true,
    },
    {
      name: "New Receipts",
      path: "/inventory/receipts/new/:index",
      component: (): Promise<unknown> =>
        import("@/components/Inventory/Receipts.vue"),
      props: true,
    },
  ],
  meta: {
    requiresAuth: true,
    title: "Inventory",
  },
};

function resetTab(path: RouteLocationNormalizedLoaded) {
  if (
    path.fullPath.includes("inventory") &&
    path.fullPath.split("/").length > 2
  ) {
    store.dispatch("inventoryModule/changeActiveTab", 0);
  }
}

export default inventoryRoutes;
