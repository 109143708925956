
import { Options, Vue } from "vue-class-component";
import Sidebar from "primevue/sidebar";
import SideBarLink from "@/components/SideBarLink.vue";
import Menu from "primevue/menu";
import { mapActions, mapGetters, mapState } from "vuex";
import Button from "primevue/button";

@Options({
  components: {
    Sidebar,
    Menu,
    SideBarLink,
    Button,
  },
  computed: {
    ...mapState(["sidemenu"]),
    ...mapGetters({
      getAccess: "session/getAccess",
    }),
  },
  props: {
    items: Array,
  },
  methods: {
    ...mapActions({
      toggleMenu: "sidemenu/toggle",
      changeTitle: "title/changeTitle",
    }),
    toggleSideMenu() {
      this.toggleMenu();
    },
    hasAccess(path: string) {
      return this.getAccess(path);
    },
    changeToolBarTitle(sideMenuLabel: any) {
      this.changeTitle(sideMenuLabel.label);
    },
  },
})
export default class SideMenu extends Vue {}
