import ApiService from "./ApiService";
import Customer from "@/types/customer";
import ClientAPI from "./ClientAPI";
import { NotificationType } from "@/types/notification";

export default class CustomerService extends ApiService {
  private cache = true;
  constructor() {
    super("customers", [{ name: "customers", keyPath: "cust_id" }]);
  }

  async getCustomer(id: any, Client: any, correls?: any) {
    if (this.isOffline) {
      return new Promise((resolve, reject) => {
        this.dbStore
          ?.getRecord("customers", id)
          .then((results) => {
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get(`customer/${id}`, { Client: Client, correls: correls })
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecord("customers", results);
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async fetchCustOptions(selection: string) {
    const params = {
      selection,
    };

    if (this.isOffline) {
      const cacheSearchFunction = (value: any) => {
        return Object.values(value).some((value) => {
          if (value as string) {
            return String(value)
              .toLowerCase()
              .includes(selection.toLowerCase());
          } else {
            return false;
          }
        });
      };

      return new Promise((resolve, reject) => {
        this.dbStore
          ?.searchRecords("customers", cacheSearchFunction)
          .then((results) => {
            resolve({ cust_items: results });
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get("customers", params)
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecords(
                "customers",
                (results as { cust_items: any[] }).cust_items,
              );
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async getAllCustomers(params: any) {
    if (this.isOffline) {
      const cacheSearchFunction = (value: any) => {
        const statusMatch =
          params.activeOnly != ""
            ? params.activeOnly === "Y" && !value.inactive_date
            : true;
        return (
          statusMatch &&
          Object.values(value).some((value) => {
            if (value as string) {
              return String(value)
                .toLowerCase()
                .includes(params.selection.toLowerCase());
            } else {
              return false;
            }
          })
        );
      };

      return new Promise((resolve, reject) => {
        this.dbStore
          ?.searchRecords(
            "customers",
            cacheSearchFunction,
            params.rangeStart,
            params.rangeEnd,
          )
          .then((results) => {
            resolve({ cust_items: results });
          })
          .catch((error) => {
            reject(error);
          });
      });
    } else {
      return new Promise((resolve, reject) => {
        ClientAPI.Get(`customers`, params)
          .then((results) => {
            if (this.cache) {
              this.dbStore?.putRecords(
                "customers",
                (results as { cust_items: any[] }).cust_items,
              );
            }
            resolve(results);
          })
          .catch((error) => {
            reject(error);
          });
      });
    }
  }

  async newCustomer(newCust: Customer, returnRecord?: string) {
    const payload = {
      ...newCust,
    };

    return ClientAPI.Post(`customer?returnRecord=${returnRecord}`, payload);
  }

  async postDealSheet(dealsheet: any, Client: string) {
    const params = {
      client: Client,
    };

    return ClientAPI.Post(`customers/dealsheet`, dealsheet, params);
  }

  async updateCustomer(
    custId: string,
    oldCust: Customer,
    newCust: Customer,
    Client: string,
    user?: string,
    populateBlankMailingAddress?: string,
  ) {
    const payload = {
      user,
      oldCust,
      newCust,
    };
    const params = {
      client: Client,
      populateBlankMailingAddress: populateBlankMailingAddress,
    };

    const error = () =>
      ClientAPI.displayNotification(
        NotificationType.ERROR,
        "Error updating customer #" + custId,
      );
    const success = () =>
      ClientAPI.displayNotification(
        NotificationType.SUCCESS,
        "Customer #" + custId + " updated successfully",
      );

    return ClientAPI.Put(`customer/${custId}`, payload, params, success, error);
  }
}
