import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import AccountingPayablesState from "@/types/state/accountingPayables";
import AccountsPayableService from "@/services/AccountsPayable";

const accountsPayableService = new AccountsPayableService();

const namespaced = true;

export const state: AccountingPayablesState = {
  payables: null,
  activeTab: 0,
  defaultDateRanges: [
    { label: "7", range: [getDate(0), getDate(7)] },
    { label: "8-14", range: [getDate(8), getDate(14)] },
    { label: "15-21", range: [getDate(15), getDate(21)] },
    { label: "22-28", range: [getDate(22), getDate(28)] },
  ],
  customDateRanges: [],
  kpi: null,
};

export const getters: GetterTree<AccountingPayablesState, RootState> = {
  getPayables: (state) => {
    return state.payables;
  },
  getActiveTab: (state) => {
    return state.activeTab;
  },
  getDefaultDateRanges: (state) => {
    return state.defaultDateRanges;
  },
  getCustomDateRanges: (state) => {
    return state.customDateRanges;
  },
  getDefaultPayablesByTabIndex: (state) => (index: number) => {
    return state.payables?.filter((item, indx) => indx === index);
  },
  getDefaultPayablesByTabLabel: (state) => (label: string) => {
    return state.payables?.filter((item) => item.label === label);
  },
  getKpi: (state) => {
    return state.kpi;
  },
  getDefaultTabsLength: (state) => {
    return state.defaultDateRanges.length;
  },
  getTabsLength: (state) => {
    return state.defaultDateRanges.length + state.customDateRanges.length;
  },
};

export const mutations: MutationTree<AccountingPayablesState> = {
  SET_PAYABLES(state, data) {
    state.payables = data;
  },
  SET_ACTIVE_TAB(state, tab) {
    state.activeTab = tab;
  },
  ADD_CUSTOM_DATE_RANGE(state, range) {
    state.customDateRanges.push({ ...range, label: range.label.trim() });
  },
  UPDATE_CUSTOM_DATE_RANGE(state, range) {
    state.customDateRanges = state.customDateRanges.map((item) => {
      if (item.label === range.label) {
        return range;
      }
      return item;
    });
  },
  REMOVE_CUSTOM_DATE_RANGE(state, rangeLabel) {
    state.customDateRanges = state.customDateRanges.filter(
      (item) => item.label !== rangeLabel,
    );
  },
  ADD_PAYABLES_ITEM(state, item) {
    if (!state.payables) {
      state.payables = [];
      state.payables.push(item);
    }
    const index = state.payables.findIndex(
      (payable) => payable.label === item.label,
    );
    if (index === -1) {
      state.payables.push(item);
    } else {
      state.payables[index] = item;
    }
  },
  SET_KPI(state, kpi) {
    state.kpi = kpi;
  },
};

export const actions: ActionTree<AccountingPayablesState, RootState> = {
  setActiveTab({ commit }, tab) {
    commit("SET_ACTIVE_TAB", tab);
  },
  addCustomDateRange({ commit }, range) {
    commit("ADD_CUSTOM_DATE_RANGE", range);
  },
  updateCustomDateRange({ commit }, range) {
    commit("UPDATE_CUSTOM_DATE_RANGE", range);
  },
  removeCustomDateRange({ commit }, rangeLabel) {
    commit("REMOVE_CUSTOM_DATE_RANGE", rangeLabel);
  },

  fetchAccountsPayable(
    { commit, dispatch },
    {
      id,
      po,
      status,
      type,
      vendorId,
      dueDateStart,
      dueDateEnd,
      sortBy,
      sortOrder,
      rangeLabel,
    },
  ) {
    return new Promise((resolve, reject) => {
      // Old implementation, this should be addapted to the new one
      accountsPayableService
        .getAccountsPayables(
          id,
          po,
          status,
          undefined,
          undefined,
          vendorId,
          dueDateStart,
          dueDateEnd,
          sortBy,
          sortOrder,
          "vendor_name",
          type,
        )
        .then((response: any) => {
          commit("ADD_PAYABLES_ITEM", {
            label: rangeLabel,
            items: response.ap_items,
          });
          resolve({ success: true });
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false });
        });
    });
  },
  fetchAccountsPayableKpi({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      accountsPayableService
        .getAccountsPayablesKpi()
        .then((response: any) => {
          commit("SET_KPI", response);
          resolve({ success: true });
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false });
        });
    });
  },
};

export const accountingPayables: Module<AccountingPayablesState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};

function getDate(days: number): string {
  if (days < 1) {
    return new Date().toString();
  }
  const newDate = new Date();
  newDate.setDate(newDate.getDate() + days);

  return newDate.toString();
}
