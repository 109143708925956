
import { defineComponent } from "vue";
import BarcodeForm from "@/components/Scan/BarcodeForm.vue";
import Footer from "@/components/Footer.vue";

export default defineComponent({
  components: {
    BarcodeForm,
    Footer,
  },
});
