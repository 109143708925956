import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import WorkCenterService from "@/services/WorkCenterService";
import { WorkCenter } from "@/types/workCenter";

const namespaced = true;

const workCenterService = new WorkCenterService(
  process.env.VUE_APP_ABSTRACTION_API,
);

interface WorkCentersState {
  workcenter_items: Array<WorkCenter>;
}

export const state: WorkCentersState = {
  workcenter_items: [],
};

export const getters: GetterTree<WorkCentersState, RootState> = {
  getWorkCenterItems: (state) => {
    return state.workcenter_items;
  },
};

export const mutations: MutationTree<WorkCentersState> = {
  SET_DATA(state, workcenter_items) {
    state.workcenter_items = workcenter_items;
  },
};

export const actions: ActionTree<WorkCentersState, RootState> = {
  async fetchWorkCenters({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.workcenter_items.length > 0) {
        resolve(state);
      } else {
        workCenterService
          .getWorkCenters({})
          .then((response: any) => {
            if (response?.length > 0) {
              commit("SET_DATA", response || []);
              resolve(response);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const workCenters: Module<WorkCentersState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
