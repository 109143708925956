import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import RepService from "@/services/RepService";

const namespaced = true;
const repService = new RepService();

interface RepState {
  rep_items: Array<any>;
}

export const state: RepState = {
  rep_items: [],
};

export const getters: GetterTree<RepState, RootState> = {
  getReps: (state) => {
    return state.rep_items;
  },
};

export const mutations: MutationTree<RepState> = {
  SET_DATA(state, rep_items) {
    state.rep_items = rep_items;
  },
};

export const actions: ActionTree<RepState, RootState> = {
  async fetchReps({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.rep_items.length > 0) {
        resolve(state);
      } else {
        repService
          .getReps({})
          .then((response: any) => {
            if (response.rep_items?.length > 0) {
              commit("SET_DATA", response.rep_items || []);
              resolve(response);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const rep: Module<RepState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
