import { ChangeDateItem } from "./controls/ship";
import Customer, { ShipItem } from "./customer";
import store from "@/store";
import Utils from "@/utility/utils";
import Rep from "./rep";
import Stax from "./stax";
import lodash from "lodash";
import { Field } from "./fdict";
import { FDICT_SO } from "@/utility/fdicts/so";

export default class SalesOrder {
  so_id = "";
  date = "";
  sold_to = "";
  acct_release_id = "";
  //attachments_items: Array<AttachmentItem> = [];
  attachments_items: any;
  change_date_items = [] as ChangeDateItem[];
  cust_name = "";
  cm_id_items = [] as CreditMemoItem[];
  currency_code = "";
  creditcard_no_items = [] as CreditCardItem[];
  coupon_id_items = [] as CouponIdItem[];
  ext = "";
  drop_ship = "";
  edi_order = "";
  exchange_rate = "";
  end_user_cntry = "";
  end_user = "";
  end_user_stmt = "";
  export_list_items = [] as ExportListItem[];
  first_ship_only = "";
  freight_amount = "";
  freight_type = "";
  freight_lock = "";
  frt_serv_code = "";
  frt_markup_amt = "";
  last_rma_number = "";
  last_rma_date = "";
  partials_ok = "";
  order_source = "";
  pending_ship = "";
  payment_plan = "";
  prepay = "";
  quote_no = "";
  pkg_type_code_items = [] as PackageTypeCodeItem[];
  book_date = "";
  contact = "";
  phone = "";
  po_number = "";
  ship_via = "";
  status = "";
  terms_code = "";
  fob = "";
  cust_code = "";
  close_date = "";
  last_ship_no = "";
  last_date = "";
  stamp_date = "";
  stamp_id = "";
  type = "";
  freight_code = "";
  print_date = "";
  plan_group = "";
  co_code = "";
  order_total_sum = "";
  ship_seq = "";
  ship_city = "";
  ship_attn = "";
  sat_delivery = "";
  ship_address_items = [] as ShipAddress[];
  ship_state = "";
  ship_geocode = "";
  ship_country = "";
  ship_comm = "";
  ship_email = "";
  ship_frt_acct = "";
  ship_name = "";
  ship_phone = "";
  ship_zip = "";
  shipper_account = "";
  rep_items = [] as Rep[];
  lis_items = [] as LisItems[];
  contact_id = "";
  contact_email = "";
  bill_to = "";
  resale_number = "";
  hold_date = "";
  hold = "";
  notes = "";
  tax_amount = "";
  signature = "";
  signature_type = "";
  signature_date = "";
  signature_time = "";
  signature_by = "";
  terns_code = "";
  tax_rate = "";
  tax_freight = "";
  tax_codes_items = [] as TaxCodeItem[];
  pos_order_code = "";
  [custom_fields: string]: any;

  constructor() {
    this.initCustomFields();
  }

  initFromSalesOrder(so: SalesOrder) {
    lodash.assign(this, so);
    this.initCustomFields();
  }

  getArrayAddress(): Array<string> {
    let s: Array<string> = [];
    const addressItems = this.ship_address_items || [];
    s = (addressItems?.map((item) => item.ship_address) as string[]) || [];
    const city = this.ship_city || "";
    const state = this.ship_state || "";
    const zip = this.ship_zip || "";
    const country = this.ship_country || "";
    const validCityStateZip = city && state && zip;
    if (validCityStateZip) {
      s.push(city + ", " + state + " " + zip);
    }

    if (country) {
      s.push(country);
    }

    return s;
  }

  initCustomFields() {
    if (!store) {
      return;
    }
    const customFields: Field[] = store.getters["fdict/customFields"]("SO");

    const customFieldsLineItems: Field[] = store.getters["fdict/customFields"](
      "SO",
      FDICT_SO.LIS,
    );

    if (!this.custom_fields) {
      this.custom_fields = {};
    }

    customFields.forEach((field) => {
      const json_name = field.json_name;
      if (!(json_name in this) && json_name in this.custom_fields) {
        // If the field is not in the order object but is in the custom fields object, set the field to the custom field value
        this[field.json_name] = this.custom_fields[field.json_name];
      }

      if (!field.multi_line || field.multi_line !== "Y") {
        this.custom_fields[field.json_name] = field.default_selection || "";
      } else {
        // Initialize multifield to an empty array
        this.custom_fields[field.json_name] = [];
      }

      if (this[field.json_name]) {
        this.custom_fields[field.json_name] = this[field.json_name];
      }

      if (!this.custom_fields[field.json_name]) {
        this.custom_fields[field.json_name] = field.default_selection || "";
      }
    });

    this.lis_items?.forEach((item) => {
      customFieldsLineItems.forEach((field) => {
        if (!item.custom_fields) {
          item.custom_fields = {};
        } else if (
          !item[field.json_name] &&
          item.custom_fields[field.json_name]
        ) {
          // If the field is not in the order object but is in the custom fields object, set the field to the custom field value
          item[field.json_name] = item.custom_fields[field.json_name];
        }

        if (!field.multi_line || field.multi_line !== "Y") {
          item.custom_fields[field.json_name] = field.default_selection || "";
        } else {
          // Initialize multifield to an empty array
          item.custom_fields[field.json_name] = [];
        }

        if (item[field.json_name]) {
          item.custom_fields[field.json_name] = item[field.json_name];
        }
      });
    });
  }

  setOrderAddress(
    customer: Customer,
    address: ShipItem,
    tax_codes: Stax[] = [],
  ) {
    if (!address) {
      if (customer.ship_seq_items && customer.ship_seq_items.length > 0) {
        address = customer.ship_seq_items[0];
      }
    }

    if (!address) {
      this.ship_address_items = [];
      if (customer.address_items && customer.address_items.length > 0) {
        this.ship_address_items = customer.address_items.map((item: any) => {
          return {
            ship_address: item.address,
          };
        });
      }

      if (!this.ship_address_items) {
        this.ship_address_items = [{ ship_address: "" }];
      }

      this.ship_seq = "CUST";
      this.ship_city = customer.sort_city || "";
      this.ship_state = customer.sort_state || "";
      this.ship_zip = customer.sort_zip || "";
      this.ship_country = customer.sort_country || "";
      this.ship_name = customer.name || "";
      this.ship_phone = customer.phone || "";
      this.contact_email = "";
      this.tax_codes_items = [];
      if (customer.tax_codes_items && customer.tax_codes_items.length > 0) {
        this.tax_codes_items = customer.tax_codes_items.map((item: any) => {
          const taxCode = tax_codes.find(
            (code: Stax) => code.id === item.tax_codes,
          );
          return {
            tax_codes: item.tax_codes,
            tax_code_rate: taxCode?.rate || "",
          };
        });
      }

      return;
    }

    this.ship_address_items = [];
    if (address.ship_to_items && address.ship_to_items.length > 0) {
      this.ship_address_items = address.ship_to_items.map((item: any) => {
        return {
          ship_address: item.ship_to,
        };
      });
    }

    if (!this.ship_address_items) {
      this.ship_address_items = [{ ship_address: "" }];
    }

    this.ship_seq = address.ship_seq || "";
    this.ship_city = address.ship_city || "";
    this.ship_state = address.ship_state || "";
    this.ship_zip = address.ship_zip || "";
    this.ship_country = address.ship_country || "";
    this.ship_name = address.ship_name || "";
    this.ship_phone = address.ship_phone || "";
    this.contact_email = address.ship_email || "";
    this.ship_comm = address.ship_comm || "";
    this.ship_email = address.ship_email || "";
    this.ship_attn = address.ship_attn || "";
    this.tax_codes_items = [];
    if (address.ship_to_tax_items && address.ship_to_tax_items.length > 0) {
      this.tax_codes_items = address.ship_to_tax_items.map((item: any) => {
        const taxCode = tax_codes.find(
          (code: Stax) => code.id === item.ship_to_tax,
        );
        return {
          tax_codes: item.ship_to_tax,
          tax_code_rate: taxCode?.rate || "",
        };
      });
    }
  }

  get shipItemAddress(): ShipItem {
    return this.getAddressAsShipItem();
  }

  get isObject(): boolean {
    // really don't like this but I need to know if the object has been initialized since loading from draft or edit does not automatically set the object with the defined methods
    return true;
  }

  reorderLineItems() {
    this.lis_items?.forEach((item: LisItems, index: number) => {
      item.lis = (index + 1).toString();
    });
  }

  getAddressAsShipItem(): ShipItem {
    const shipItem = new ShipItem();
    shipItem.ship_seq = this.ship_seq;
    shipItem.ship_city = this.ship_city;
    shipItem.ship_state = this.ship_state;
    shipItem.ship_zip = this.ship_zip;
    shipItem.ship_name = this.ship_name;
    shipItem.ship_country = this.ship_country;
    shipItem.ship_attn = this.ship_attn;
    shipItem.ship_comm = this.ship_comm;
    shipItem.ship_phone = this.ship_phone;
    shipItem.ship_fax = "";
    shipItem.ship_email = this.ship_email;
    shipItem.ship_to_items = this.ship_address_items?.map((item) => {
      return {
        ship_to: item.ship_address,
      };
    });
    shipItem.ship_to_tax_items = this.tax_codes_items?.map((item) => {
      return {
        ship_to_tax: item.tax_codes,
      };
    });
    shipItem.ship_acct_no_items = [];

    return shipItem;
  }
}

export interface CreditMemoItem {
  cm_id: string;
  cm_amount: string;
}

export interface CreditCardItem {
  cmcreditcard_no_id: string;
  cardholder_name: string;
  creditcard_exp: string;
  creditcard_cvv: string;
  creditcard_amt: string;
  cc_conv_fee: string;
  cc_addr: string;
  cc_city: string;
  cc_state: string;
  cc_country: string;
  cc_zip: string;
  credit_card_id: string;
  creditcard_err: string;
  creditcard_type: string;
  creditcard_tran: string;
  creditcard_post: string;
}

export interface CouponIdItem {
  coupon_id: string;
  coup_disc_amt: string;
  coup_disc_pct: string;
  coup_max_disc: string;
  coup_li_items: Array<CouponLineItem>;
  coup_min_ord: string;
  coupon_type: string;
}

export interface CouponLineItem {
  coup_li: string;
  coup_li_disc: string;
  coup_ship_amt: string;
}

export interface ExportListItem {
  export_list: string;
  export_list_chk: string;
}

export interface ShipAddress {
  ship_address: string;
}

export interface TaxCodeItem {
  tax_codes: string;
  tax_code_rate?: string;
}

export interface PackageTypeCodeItem {
  pkg_type_code: string;
  pkg_length: string;
  pkg_width: string;
  pkg_height: string;
  pkg_weight: string;
  pkg_dim_uom: string;
  pkg_weight_uom: string;
  pkg_value: string;
}

export interface AttachmentItem {
  attachments: string;
  attachment_desc: string;
}

export interface LisItems {
  lis: string;
  li_parts: string;
  li_revs: string;
  li_order_qtys: string;
  li_open_qtys: string;
  li_prices: string;
  li_order_total: string;
  li_fg_locs: string;
  li_total_ship: string;
  li_sales_accts: string;
  wrap_desc: string;
  li_descs_items: Array<LiDescsItems>;
  li_sched_dates_items: Array<LiSchedDatesItems>;
  li_reps_items: Array<LiRepsItems>;
  li_ship_nos_items: Array<LiShipNosItems>;
  li_discs?: string;
  cg_loc?: string;
  cust_part?: string;
  li_end_use?: string;
  li_notes?: string;
  li_holds?: string;
  li_hold_dates?: string;
  li_quote_no?: string;
  li_quote_li?: string;
  li_taxables?: string;
  li_wos_items?: Array<LiWosItems>;
  project_id?: string;
  po_cost?: string;
  miva_li_no?: string;
  lot_number?: string;
  po_id?: string;
  po_li?: string;
  price_per?: string;
  task_id?: string;
  vendor?: string;
  li_disc_amts?: string;
  [custom_fields: string]: any;
}

export interface LiWosItems {
  li_wos: string;
}

export interface LiDescsItems {
  li_descs: string;
}

export interface LiSchedDatesItems {
  li_sched_dates: string;
  li_sched_qtys: string;
  commited: string;
  required?: string;
}

export interface LiRepsItems {
  li_reps: string;
  li_comms: string;
}

export interface LiShipNosItems {
  li_ship_nos: string;
  li_ship_dates: string;
  li_ship_qtys: string;
}
