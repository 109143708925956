import axios, { AxiosInstance } from "axios";
import store from "@/store";
import ClientAPI from "./ClientAPI";

export default class PaymentService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    });
  }

  async Pay(payload: any) {
    //TODO: test with regular invoice payment
    return ClientAPI.Post("payment", payload);
  }

  async PayACH(
    amount: number,
    account: string,
    route: string,
    type: string,
    cust: string,
    invoices: Array<any>,
    Client: string,
    waive_conv_fee: boolean,
    data_source: string,
    receipt_email_address: string,
    meta?: any,
  ) {
    let li = 0;
    invoices = invoices.map((elem: any) => {
      li++;
      return {
        arid: elem.arId,
        amount: elem.balance.toFixed(2),
        li,
        ar_app_amt: elem.balance.toFixed(2),
      };
    });
    const body = {
      Client,
      amount: amount.toFixed(2),
      route,
      account,
      type,
      invoices,
      cust,
      waive_conv_fee,
      data_source,
      receipt_email_address,
      meta,
    };
    return ClientAPI.Post("ACHpayment", body);
  }
  async getReceipt(receiptRequest: {
    key: string;
    user?: string;
    reg?: string;
    email?: string;
    format?: string;
    printer?: string;
  }) {
    const { key, email, user, reg, format, printer } = receiptRequest;
    const params = {
      id: key,
      email,
      user,
      reg,
      format,
      printer,
    };
    return ClientAPI.Get(`Receipt/${key}/pdf`, params);
  }
  async getDrawerCodes(
    key: string,
    user?: string,
    reg?: string,
    signData?: boolean,
  ) {
    const params = {
      id: key,
      user: user,
      reg: reg,
      signData: signData,
    };
    return ClientAPI.Get(`CashDrawer/${key}/openDrawer`, params);
  }
  async getCcToken(store: string, reg: string) {
    const params = {
      store,
      reg,
    };
    return ClientAPI.Get(`tokenize`, params);
  }
}
