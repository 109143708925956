import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import EnvState from "@/types/state/env";
import EnvServices from "@/services/EnvServices";

const namespaced = true;

const envService = new EnvServices(process.env.VUE_APP_ABSTRACTION_API);

export const state: EnvState = {
  modules: [],
};

export const getters: GetterTree<EnvState, RootState> = {
  getModules(state: any) {
    return state.modules;
  },
};

export const mutations: MutationTree<EnvState> = {
  SET_MODULES(state: any, modules: Array<string>) {
    state.modules = modules;
  },
};

export const actions: ActionTree<EnvState, RootState> = {
  fetchModules({ commit }) {
    return new Promise((resolve, reject) => {
      envService
        .fetchModules()
        .then((response) => {
          commit("SET_MODULES", response.modules);
          resolve(response.modules);
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
};

export const env: Module<EnvState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
