import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";

export default class LotService {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getLot(ids: any, fieldnames: string) {
    const params = {
      fieldnames,
      ids,
    };
    return ClientAPI.Get(`lots`, params);
  }
}
