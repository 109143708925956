export default interface Notification {
  id: number;
  message: string;
  type: string;
  dialog: boolean;
}

export enum NotificationType {
  ERROR = "error",
  SUCCESS = "success",
  WARN = "warn",
}

export const ADD_NOTIFICATION = "notification/add";
export const ADD_ERROR_NOTIFICATION = "notification/addError";
export const ADD_SUCCESS_NOTIFICATION = "notification/addSuccess";
