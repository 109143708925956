const salesRoutes = {
  path: "/sales",
  name: "Sales",
  redirect: "/sales/orders",
  component: (): Promise<unknown> => import("../views/Sales.vue"),
  children: [
    // Orders paths
    {
      name: "Sales Orders",
      path: "/sales/orders",
      component: (): Promise<unknown> =>
        import("../components/Sales/Sales.vue"),
      props: true,
    },
    {
      name: "Order",
      path: "/sales/orders/:orderId",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Orders/OrdersTab.vue"),
      props: true,
    },
    {
      name: "Line Item",
      path: "/sales/orders/:orderId/line-item/:itemId",
      props: true,
      component: (): Promise<unknown> =>
        import("@/components/Sales/LineItems/LineItem.vue"),
    },
    // {
    //   name: "Order Detail Line Item",
    //   path: "/sales/orders/:orderId/detail-line-item",
    //   component: (): Promise<unknown> => import("@/components/Sales/Orders/SalesDetailLineItem.vue"),
    //   props: true,
    // },
    // {
    //   name: "Order Summary Line Item",
    //   path: "/sales/orders/:orderId/summary-line-item",
    //   component: (): Promise<unknown> => import("@/components/Sales/Orders/SalesSummaryLineItem.vue"),
    //   props: true,
    // },
    {
      name: "Order Ship/Tax/Credit",
      path: "/sales/orders/:orderId/ship-tax-credit",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Orders/SalesShipTaxCredit.vue"),
      props: true,
    },
    {
      name: "Order Tracking",
      path: "/sales/orders/:orderId/tracking",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Orders/SalesTracking.vue"),
      props: true,
    },
    {
      name: "Change History",
      path: "/sales/orders/:orderId/change-history",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Orders/SalesChangeHistory.vue"),
      props: true,
    },
    {
      name: "Coupons",
      path: "/sales/orders/:orderId/coupons",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Orders/SalesCoupons.vue"),
      props: true,
    },
    {
      name: "Attachments",
      path: "/sales/orders/:orderId/attachments",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Orders/SalesAttachments.vue"),
      props: true,
    },
    {
      name: "Export",
      path: "/sales/orders/:orderId/export",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Orders/SalesExport.vue"),
      props: true,
    },
    {
      name: "Logs",
      path: "/sales/orders/:orderId/logs",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Orders/SalesLogs.vue"),
      props: true,
    },
    // Opportunities paths
    {
      name: "Sales Opportunities",
      path: "/sales/opportunities",
      component: (): Promise<unknown> =>
        import("../components/Sales/SalesOpps.vue"),
      props: true,
    },
    {
      name: "Opportunity",
      path: "/sales/opportunities/:oppId",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Opps/OpportunitiesTab.vue"),
      props: true,
    },
    // Quotes paths
    {
      name: "Sales Quotes",
      path: "/sales/quotes",
      component: (): Promise<unknown> =>
        import("../components/Sales/SalesQuotes.vue"),
      props: true,
    },
    {
      name: "Quote",
      path: "/sales/quotes/:quoteId",
      component: (): Promise<unknown> =>
        import("@/components/Sales/Quotes/QuotesTab.vue"),
      props: true,
    },
  ],
  meta: {
    requiresAuth: true,
    title: "Sales",
  },
};

export default salesRoutes;
