import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import titleState from "@/types/state/title";

const namespaced = true;

export const state: titleState = {
  title: "Dashboard",
  subtitle: "",
};

export const getters: GetterTree<titleState, RootState> = {
  getTitle: (state) => {
    return state.title;
  },
  getSubTitle: (state) => {
    return state.subtitle;
  },
};

export const mutations: MutationTree<titleState> = {
  NEXT_TITLE(state, title) {
    state.title = title;
    state.subtitle = "";
  },
  NEXT_SUB_TITLE(state, title) {
    state.subtitle = title;
  },
};

export const actions: ActionTree<titleState, RootState> = {
  changeTitle({ commit }, title) {
    commit("NEXT_TITLE", title);
  },
  changeSubTitle({ commit }, title) {
    commit("NEXT_SUB_TITLE", title);
  },
};

export const title: Module<titleState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
