import ApiService from "./ApiService";

export default class StaxService extends ApiService {
  constructor() {
    super();
  }

  async getStax(client: any) {
    return this.Get(`stax`, { client });
  }

  async calcStax(order: any) {
    return this.Post(`calcstax`, order);
  }
}
