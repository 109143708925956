import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";
export default class OrdersService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }
  async getOrders(part: string, rangeStart: number, rangeEnd: number) {
    const params = {
      rangeStart,
      rangeEnd,
      part,
    };
    return ClientAPI.Get(`orders`, params);
  }
}
