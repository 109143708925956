import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";
export default class AttachmentService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getAttachments(ids: string, rangeStart: number, rangeEnd: number) {
    const params = {
      ids,
      rangeStart,
      rangeEnd,
    };

    return ClientAPI.Post(`attachments/search`, params);
  }

  async downloadAttachment(id: string) {
    return ClientAPI.Get(`attachments/${id}`, {});
  }

  async addAttachment(payload: any) {
    return ClientAPI.Post(`attachments`, payload);
  }

  async deleteAttachment(payload: any) {
    return ClientAPI.Post(`attachments/delete`, payload);
  }
}
