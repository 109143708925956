import ApiService from "./ApiService";

export default class FiscalYearService extends ApiService {
  constructor() {
    super();
  }

  async getFiscalYears() {
    return this.Get(`FiscalYear/years`, {});
  }
}
