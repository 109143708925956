import mitt from "mitt";

export default mitt();

export const AppEvents = {
  PosFocusPartSearch: "PosFocusPartSearch",
  PosClearDraftOrders: "PosClearDraftOrders",
  PosReloadInvoices: "PosReloadInvoices",
  ShowKeyboardShortcuts: "ShowKeyboardShortcuts",
};
