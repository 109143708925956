import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import InvoiceState from "@/types/state/invoice";
import Invoice from "@/types/invoice";
import Pdf from "@/types/pdf";
import InvoiceService from "@/services/InvoiceService";
import CustomerService from "@/services/CustomerService";

const namespaced = true;
const service = new InvoiceService();
const customerservice = new CustomerService();
export const state: InvoiceState = {
  customer: null,
  allCustomers: [],
  invoices: [],
  loading: false,
  page: 1,
  rangeStart: 1,
  rangeEnd: 100,
  isLast: false,
  invoiceView: [],
  loadingInvoices: [],
  sortedCust: false,
  sortedBalance: false,
  sortedDueDate: true,
  sortingString: "by due.date ",
  dateFilter: null,
  statusFilter: null,
  itemsPerPage: 10,
  invoicesToPay: [],
  kpi: null,
  monthlyData: [],
};

export const getters: GetterTree<InvoiceState, RootState> = {
  getInvoices(state): Array<Invoice> {
    return state.invoices;
  },
  getMonthlyData(state) {
    return state.monthlyData;
  },
  getLoadingStatus(state): boolean {
    return state.loading;
  },
  getRange(state): number {
    return state.rangeEnd;
  },
  getPage(state): number {
    return state.page;
  },
  getLength(state): number {
    return state.invoices.length;
  },
  getInvoice(state): Array<Pdf> {
    return state.invoiceView;
  },
  getSortedBalance(state): boolean {
    return state.sortedBalance;
  },
  getSortedDueDate(state): boolean {
    return state.sortedDueDate;
  },
  getSortedCust(state): boolean {
    return state.sortedCust;
  },
  getSortString(state): string {
    return state.sortingString;
  },
  getStatusFilter(state): string | null {
    return state.statusFilter;
  },
  getDateFilter(state): Array<Date> | null {
    return state.dateFilter;
  },
  getItemsPerPage(state): number {
    return state.itemsPerPage;
  },
  getInvoicesToPay(state): Array<any> {
    return state.invoicesToPay;
  },
  getLoadingInvoices(state) {
    return state.loadingInvoices;
  },
  getCust(state) {
    return state.customer;
  },
  getAllCusts(state) {
    return state.allCustomers;
  },
  getKpi(state) {
    return state.kpi;
  },
};

export const mutations: MutationTree<InvoiceState> = {
  SET_INVOICE(state, obj) {
    state.invoiceView.push(obj);
  },
  SET_MONTHLY_DATA(state, data) {
    state.monthlyData = data;
  },
  PUSH_INVOICE(state, invoice) {
    state.invoices.push(...invoice);
  },
  TOGGLE_LOADING(state) {
    state.loading = !state.loading;
  },
  SET_LOADING(state, loading) {
    state.loading = loading;
  },
  NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  CLEAR_INVOICES(state) {
    state.invoices = [];
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  TOGGLE_SORTED_CUST(state) {
    state.sortedCust = !state.sortedCust;
    state.sortedDueDate = false;
    state.sortedBalance = false;
  },
  TOGGLE_SORTED_DUE_DATE(state) {
    state.sortedDueDate = !state.sortedDueDate;
    state.sortedBalance = false;
  },
  TOGGLE_SORTED_BALANCE(state) {
    state.sortedBalance = !state.sortedBalance;
    state.sortedDueDate = false;
  },
  SET_SORTING_STRING(state, string) {
    state.sortingString = string;
  },
  SET_STATUS_FILTER(state, status) {
    state.statusFilter = status;
  },
  SET_DATE_FILTER(state, date) {
    state.dateFilter = null;
    if (date !== null) {
      state.dateFilter = [];
      state.dateFilter.push(...date);
    }
  },
  SET_ITEMS_PER_PAGE(state, items) {
    state.itemsPerPage = items;
  },
  PUSH_INCOIVE_TO_PAY(state, invoice) {
    state.invoicesToPay.push(invoice);
  },
  REMOVE_INVOICE_TO_PAY(state, invoice) {
    const filteredarray = state.invoicesToPay.filter((inv) => {
      return invoice.arId != inv.arId;
    });
    state.invoicesToPay = filteredarray;
  },
  ADD_ID_LOADING(state, id) {
    state.loadingInvoices.push(id);
  },
  REMOVE_ID_LOADING(state, id) {
    const index = state.loadingInvoices.indexOf(id, 0);
    if (index > -1) {
      state.loadingInvoices.splice(index, 1);
    }
  },
  CLEAR_INVOICESTOPAY(state) {
    state.invoicesToPay = [];
  },
  SET_ALL_CUSTOMERS(state, customers) {
    state.allCustomers = customers;
  },
  SET_CUSTOMER(state, customer) {
    state.customer = customer;
  },
  SET_KPI(state, kpi) {
    state.kpi = kpi;
  },
};

export const actions: ActionTree<InvoiceState, RootState> = {
  setNextPage({ commit }, page) {
    commit("NEXT_PAGE", page);
  },
  async fetchMonthlyData({ commit }, { cust, month, year }) {
    service
      .getKpiInfo(cust, month, year)
      .then((response: any) => {
        commit("SET_MONTHLY_DATA", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async setInvoices(
    { commit, dispatch, state },
    {
      custId,
      dateRange,
      status,
      sortBy,
      addInvoices,
      Client,
      id,
      type,
      correls,
    },
  ) {
    commit("SET_LOADING", true);
    let dateStart = undefined;
    let dateEnd = undefined;
    if (dateRange !== null) {
      dateStart = new Date(dateRange[0])
        .toLocaleString()
        .split(",")[0]
        .toString();
      dateEnd = new Date(dateRange[1])
        .toLocaleString()
        .split(",")[0]
        .toString();
      commit("SET_DATE_FILTER", dateRange);
    } else {
      commit("SET_DATE_FILTER", null);
    }
    let sortByString = state.sortingString;
    if (sortBy === "DueDate") {
      if (state.sortedDueDate) {
        sortByString = "by.dsnd due.date ";
      } else {
        sortByString = "by due.date ";
      }
      commit("TOGGLE_SORTED_DUE_DATE");
    } else if (sortBy === "Cust") {
      if (state.sortedCust) {
        sortByString = "by.dsnd cust";
      } else {
        sortByString = "by cust";
      }
      commit("TOGGLE_SORTED_CUST");
    } else if (sortBy === "Balance") {
      if (state.sortedBalance) {
        sortByString = "by.dsnd balance";
      } else {
        sortByString = "by balance";
      }
      commit("TOGGLE_SORTED_BALANCE");
    }
    commit("SET_SORTING_STRING", sortByString);
    commit("SET_STATUS_FILTER", status);
    if (!addInvoices) {
      commit("RESET_PAGE");
    }
    await service
      .setInvoices(
        state.rangeStart,
        state.rangeEnd,
        custId,
        Client,
        dateStart,
        dateEnd,
        status,
        sortByString,
        id,
        type,
        correls,
      )
      .then((response: any) => {
        if (!addInvoices) {
          commit("CLEAR_INVOICES");
        }
        commit("PUSH_INVOICE", response.ar_items);
        commit("NEXT_RANGE");
        if (state.invoices.length == 0) {
          const notification = {
            type: "error",
            message: "No invoices Found",
          };
          dispatch("notification/add", notification, { root: true });
        }
      })
      .finally(() => {
        commit("SET_LOADING", false);
      });
  },
  async getInvoice({ commit, dispatch, state }, payload) {
    const pdf = state.invoiceView.find((i) => i.id === payload.recordId);
    if (pdf !== undefined) {
      window.open(pdf.pdf, "_blank");
    } else {
      commit("ADD_ID_LOADING", payload.recordId);
      await service
        .getInvoice(payload.recordId, payload.Client)
        .then((resp: any) => {
          const bufferArray = base64ToArrayBuffer(resp);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
          commit("SET_INVOICE", { invoice_num: payload.recordId, pdf: data });
          commit("REMOVE_ID_LOADING", payload.recordId);
        })
        .catch((error) => {
          commit("REMOVE_ID_LOADING", payload.recordId);
        });
    }
  },
  addInvoiceToPay(
    { commit, dispatch, state },
    { arId, balance, dueDate, cust },
  ) {
    const invToPay = {
      arId,
      balance,
      dueDate,
      cust,
    };
    commit("PUSH_INCOIVE_TO_PAY", invToPay);
  },
  removeInvoiceToPay({ commit }, { arId, balance, dueDate, cust }) {
    commit("REMOVE_INVOICE_TO_PAY", { arId, balance, dueDate, cust });
  },
  clearInvoicesToPay({ state, commit }) {
    commit("CLEAR_INVOICESTOPAY");
  },
  async getCustomer({ commit, dispatch, state }, { id, Client, correls }) {
    await customerservice
      .getCustomer(id, Client, correls)
      .then((response: any) => {
        commit("SET_CUSTOMER", response);
      });
  },
  async getAllCustomers({ commit, dispatch, state }, { Client }) {
    await customerservice.getAllCustomers({ Client }).then((response: any) => {
      const resp = {} as any;
      const cust_items = response.cust_items.sort((a: any, b: any) => {
        if (a.name != null && b.name != null) {
          return a.name.localeCompare(b.name);
        } else {
          return a.cust_id.localeCompare(b.cust_id);
        }
      });
      resp.cust_items = cust_items;
      commit("SET_ALL_CUSTOMERS", resp);
    });
  },
  async fetchAccountsReceivableKpi({ commit, dispatch }) {
    await service
      .getAgeingKpi()
      .then((response: any) => {
        commit("SET_KPI", response);
      })
      .catch((error: any) => {
        const notification = {
          type: "error",
          message: error,
        };
        dispatch("notification/add", notification, { root: true });
      });
  },
};

export const invoice: Module<InvoiceState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};

// Move to utils file
function base64ToArrayBuffer(data: string) {
  const bString = window.atob(data);
  const bLength = bString.length;
  const bytes = new Uint8Array(bLength);
  for (let i = 0; i < bLength; i++) {
    const ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}
