
import { Vue, Options } from "vue-class-component";
import { mapState } from "vuex";
@Options({
  computed: {
    iconClass() {
      return this.menu.to + " " + this.menu.icon + " sidebar-icon";
    },
    imageName() {
      return require(`@/assets/images/logos/current/${this.menu.imgIcon}`);
    },
    ...mapState(["sidemenu"]),
  },
  props: {
    menu: Object,
  },
})
export default class SideBarLink extends Vue {}
