import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import InventoryInquiryState from "@/types/state/inventoryInquiry";
import InventoryStateObject from "@/types/InventoryInquiryMap";
import InvPart from "@/types/invPart";

import InventoryService from "@/services/inventory";
import AllocationService from "@/services/AllocationService";
import CommitService from "@/services/CommitService";
import TransactionService from "@/services/TransactionService";
import LotService from "@/services/LotInfoService";
import OrdersService from "@/services/OrdersService";

const inventoryService = new InventoryService(
  process.env.VUE_APP_ABSTRACTION_API,
);
const allocationService = new AllocationService(
  process.env.VUE_APP_ABSTRACTION_API,
);
const commitService = new CommitService(process.env.VUE_APP_ABSTRACTION_API);
const transactionService = new TransactionService(
  process.env.VUE_APP_ABSTRACTION_API,
);
const lotService = new LotService(process.env.VUE_APP_ABSTRACTION_API);
const ordersService = new OrdersService(process.env.VUE_APP_ABSTRACTION_API);

const namespaced = true;
const DEFAULT_INNER_TAB_ROUTE = "/inventoryInquiry/summary";

export const state: InventoryInquiryState = {
  InventoryMap: [],
  inventoryTabIndex: 0,
  rangeStart: 1,
  rangeEnd: 100,
  page: 1,
  firstRow: 1,
};

export const getters: GetterTree<InventoryInquiryState, RootState> = {
  getPart: (state) => (index: number) => {
    return state.InventoryMap[index].part;
  },
  getOrders: (state) => (index: number) => {
    return state.InventoryMap[index].orders;
  },
  getCommits: (state) => (index: number) => {
    return state.InventoryMap[index].commits;
  },
  getCurrentTabIndex: (state) => {
    return state.inventoryTabIndex;
  },
  getInnerTabValue: (state) => {
    return (
      state.InventoryMap[state.inventoryTabIndex].partInnerTabValue ||
      DEFAULT_INNER_TAB_ROUTE
    );
  },
  getAllocs: (state) => (index: number) => {
    return state.InventoryMap[index].allocs;
  },
  getTransactions: (state) => (index: number) => {
    return state.InventoryMap[index].transactions;
  },
  getLots: (state) => (index: number) => {
    return state.InventoryMap[index].lots;
  },
};

export const mutations: MutationTree<InventoryInquiryState> = {
  PUSH_ORDERS(state, { orders, index }) {
    if (state.InventoryMap[index].orders == null) {
      state.InventoryMap[index].orders = orders;
    } else {
      state.InventoryMap[index].orders?.push(...orders);
    }
  },
  PUSH_ALLOCATIONS(state, { allocs, index }) {
    if (state.InventoryMap[index].allocs == null) {
      state.InventoryMap[index].allocs = allocs;
    } else {
      state.InventoryMap[index].allocs!.push(...allocs);
    }
  },
  PUSH_COMMITS(state, { commits, index }) {
    if (state.InventoryMap[index].commits == null) {
      state.InventoryMap[index].commits = commits;
    } else {
      state.InventoryMap[index].commits?.push(...commits);
    }
  },
  PUSH_TRANSACTIONS(state, { transactions, index }) {
    if (state.InventoryMap[index].transactions == null) {
      state.InventoryMap[index].transactions = transactions;
    } else {
      state.InventoryMap[index].transactions!.push(...transactions);
    }
  },
  PUSH_PART(state, part) {
    const inventoryState: InventoryStateObject = {
      part,
      orders: null,
      commits: null,
      allocs: null,
      transactions: null,
      lots: null,
      partInnerTabValue: DEFAULT_INNER_TAB_ROUTE,
    };
    state.InventoryMap = [inventoryState, ...state.InventoryMap];
    state.inventoryTabIndex = 0;
  },
  REMOVE_PART(state, inventoryState: InventoryStateObject) {
    const filteredIndex = state.InventoryMap.indexOf(inventoryState);

    const filtered = state.InventoryMap.filter((item) => {
      return item.part.part_no !== inventoryState.part.part_no;
    });

    if (
      filteredIndex <= state.inventoryTabIndex &&
      (filteredIndex !== 0 || filteredIndex < state.inventoryTabIndex)
    ) {
      state.inventoryTabIndex = state.inventoryTabIndex - 1;
    } else if (filteredIndex == 0) {
      state.inventoryTabIndex = 0;
    }

    state.InventoryMap = filtered;
  },
  SET_TAB_INDEX(state, tabIndex) {
    state.inventoryTabIndex = tabIndex;
  },
  SET_INNER_TAB_ITEM(state, tabName: string) {
    state.InventoryMap[state.inventoryTabIndex].partInnerTabValue = tabName;
  },
  SET_PART_TAB_INDEX(state, tabIndex: number) {
    state.inventoryTabIndex = tabIndex;
  },
  SET_LOTS(state, { lots, index }) {
    state.InventoryMap[index].lots = lots;
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  CLEAR_ORDERS(state) {
    state.InventoryMap = [];
  },
  SET_FIRST_ROW(state, row) {
    state.firstRow = row;
  },
};

export const actions: ActionTree<InventoryInquiryState, RootState> = {
  fetchPart({ state, commit, dispatch }, { partId, client, correls }) {
    return new Promise((resolve, reject) => {
      const partTabIndex = getInventoryByIndex(state, partId);
      if (partTabIndex !== -1) {
        commit("SET_TAB_INDEX", partTabIndex);
        resolve({ success: true });
      } else {
        inventoryService
          .getInventory(partId, client, correls)
          .then((response: any) => {
            instantiatePartFields(response);
            commit("PUSH_PART", response);
            resolve({ success: true });
          })
          .catch((error) => {
            reject({ success: false });
          });
      }
    });
  },
  fetchCommits({ commit, dispatch }, { part, index, client }) {
    return new Promise((resolve, reject) => {
      commitService
        .getCommit(part, client)
        .then((response: any) => {
          commit("PUSH_COMMITS", { commits: response.commit_items, index });
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false });
        });
    });
  },
  fetchAllocs({ commit, dispatch }, { part, index, client }) {
    return new Promise((resolve, reject) => {
      allocationService
        .getAllocation(part, client)
        .then((response: any) => {
          if (response.alloc_items.length == 0) {
            const value = { part_id: "", date_item: [] };
            response.alloc_items.push(value);
          }
          commit("PUSH_ALLOCATIONS", { allocs: response.alloc_items, index });
          resolve({ success: true });
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              type: "error",
              message: error.error || error.message,
            },
            { root: true },
          );
          reject({ success: false });
        });
    });
  },
  fetchOrders({ commit, dispatch }, { part, index }) {
    return new Promise((resolve, reject) => {
      ordersService
        .getOrders(part, state.rangeStart, state.rangeEnd)
        .then((response: any) => {
          commit("PUSH_ORDERS", { orders: response.order_items, index });
          commit("NEXT_RANGE");
          resolve({ success: true });
        })
        .catch((error: any) => {
          reject({ success: false, error: error });
        });
    });
  },
  fetchTransactions(
    { commit, dispatch },
    { part, rangeStart, rangeEnd, index },
  ) {
    return new Promise((resolve, reject) => {
      if (state.InventoryMap[index].part.it_id_items != null) {
        let ids = "";
        state.InventoryMap[index].part.it_date_items?.forEach((transaction) => {
          ids += transaction.it_id_items![0].it_id + " ";
        });
        transactionService
          .getTransaction(part, ids, rangeStart, rangeEnd)
          .then((response: any) => {
            commit("PUSH_TRANSACTIONS", {
              transactions: response.it_items,
              index,
            });
            resolve({ success: true });
          })
          .catch((error) => {
            reject({ success: false });
          });
      } else {
        commit("PUSH_TRANSACTIONS", { transactions: [], index });
        resolve({
          success: true,
          message: "No transactions linked to this part.",
        });
      }
    });
  },
  removePart({ commit }, name: string) {
    commit("REMOVE_PART", name);
  },
  saveInnerTabItem({ commit }, tabName: string) {
    commit("SET_INNER_TAB_ITEM", tabName);
  },
  savePartTabIndex({ commit }, tabIndex: number) {
    commit("SET_PART_TAB_INDEX", tabIndex);
  },
  fetchLots({ state, commit }, { fieldnames, index }) {
    return new Promise((resolve, reject) => {
      if (state.InventoryMap[index].part.lot_no_items != null) {
        let ids = "";
        state.InventoryMap[index].part.lot_no_items?.forEach((lot) => {
          ids += lot.lot_no + " ";
        });
        lotService
          .getLot(ids, fieldnames)
          .then((response: any) => {
            commit("SET_LOTS", { lots: response.lot_items, index });
            resolve({ success: true });
          })
          .catch(() => {
            reject({ success: false });
          });
      } else {
        commit("SET_LOTS", { lots: [], index });
        resolve({ success: true, message: "No Lots linked to this part." });
      }
    });
  },
};

export const inventoryInquiry: Module<InventoryInquiryState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

// UTILITY FUNCTIONS
const getInventoryByIndex = (state: InventoryInquiryState, id: string) => {
  const isPresent = state.InventoryMap.findIndex(
    (item: InventoryStateObject) => {
      return item.part.part_no === id;
    },
  );
  return isPresent;
};

const instantiatePartFields = (partObject: InvPart) => {
  for (const [key, value] of Object.entries(PART)) {
    if (!(key in partObject)) {
      Object.assign(partObject, {
        [key]: value,
      });
    }
  }
};

const PART: InvPart = {
  average_cost: "",
  cost_group_items: [],
  invloc_items: [],
  part_no: "",
  transactions: [],
  it_id_items: [],
  it_date_items: [],
  lots: [],
  lot_no_items: [],
  um: "",
};
