import { Module, ActionTree, MutationTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import CustomerState from "@/types/state/customer";
import CustomerService from "@/services/CustomerService";

const namespaced = true;
const service = new CustomerService();
export const state: CustomerState = {
  customer: null,
  allCustomers: [],
  recentlyViewed: [],
};

export const getters: GetterTree<CustomerState, RootState> = {
  getCust(state) {
    return state.customer;
  },
  getAllCusts(state) {
    return state.allCustomers;
  },
  getRecentlyViewed(state) {
    return state.recentlyViewed;
  },
};

export const actions: ActionTree<CustomerState, RootState> = {
  async getCustomer({ commit, dispatch, state }, { id, Client, correls }) {
    await service.getCustomer(id, Client, correls).then((response: any) => {
      commit("SET_CUSTOMER", response);
    });
  },

  async getAllCustomers({ commit, dispatch, state }, params) {
    await service.getAllCustomers(params).then((response: any) => {
      const resp = response;
      if (response.cust_items) {
        commit("SET_ALL_CUSTOMERS", resp);
      }
    });
  },
  fetchCacheCustomers({ commit, dispatch, state }, params) {
    return new Promise((resolve, reject) => {
      service
        .getAllCustomers(params)
        .then((response: any) => {
          const resp = response;
          resolve({ success: true, count: response.cust_items.length });
        })
        .catch((error) => {
          resolve({ success: false, error: error });
        });
    });
  },
  addRecentlyViewed({ commit, state }, customer) {
    // Find Index of Exisiting Customer in Recently Viewed
    const existingViewedIndex = state.recentlyViewed.findIndex((cust) => {
      return cust.cust_id === customer.cust_id;
    });
    // Check If Customer Esist or Not
    if (existingViewedIndex !== -1) {
      commit("REMOVE_RECENTLY_VIEWED_BY_INDEX", existingViewedIndex);
    }
    commit("PUSH_RECENTLY_VIEWED", customer);
  },
  removeRecentlyViewedCustomer({ commit, state }, id) {
    // Find Index of Customer in Recently Viewed By Id
    const index = state.recentlyViewed.findIndex((cust) => {
      return cust.cust_id === id;
    });
    commit("REMOVE_RECENTLY_VIEWED_BY_INDEX", index);
  },
};

export const mutations: MutationTree<CustomerState> = {
  SET_CUSTOMER(state, cust) {
    state.customer = cust;
  },
  SET_ALL_CUSTOMERS(state, custs) {
    state.allCustomers = custs;
  },
  PUSH_RECENTLY_VIEWED(state, customer) {
    state.recentlyViewed = [customer, ...state.recentlyViewed];
  },
  REMOVE_RECENTLY_VIEWED_BY_INDEX(state, index) {
    state.recentlyViewed.splice(index, 1);
  },
};

export const customer: Module<CustomerState, RootState> = {
  namespaced,
  state,
  actions,
  mutations,
  getters,
};
