import store from "../store";
import ClientAPI from "./ClientAPI";

export default class InvoiceService {
  async setInvoices(
    rangeStart: number,
    rangeEnd: number,
    custId: string,
    Client: string,
    dateStart?: string,
    dateEnd?: string,
    status?: string,
    sortBy?: string,
    ids?: string,
    type?: string,
    correls?: string,
  ) {
    const params = {
      rangeStart: rangeStart.toString(),
      rangeEnd: rangeEnd.toString(),
      dateStart,
      dateEnd,
      status,
      sortBy,
      cust: custId ? [custId] : [],
      ids,
      type,
      correls,
    };
    return ClientAPI.Post(`invoices/search`, params);
  }

  async setAllInvoices(
    custId: string,
    Client: string,
    dateStart?: string,
    dateEnd?: string,
    status?: string,
    statuses?: [],
    sortBy?: string,
    ids?: string,
    type?: string,
    types?: [],
    correls?: string,
  ) {
    const params = {
      dateStart,
      dateEnd,
      statuses,
      sortBy,
      cust: custId ? [custId] : [],
      ids,
      types,
      correls,
    };
    return ClientAPI.Post(`invoices/search`, params);
  }

  async getInvoice(key: string, email?: any) {
    let params;
    if (email) {
      params = {
        email: email.To,
        subject: email.Subject,
        body: email.Body,
        from: email.From,
        cc: email.Cc,
        bcc: email.Bcc,
        html: email.Html,
      };
    }

    return ClientAPI.Get(`invoices/${key}/pdf`, params);
  }

  async getKpiInfo(cust: string, month: string, year: string) {
    const params = {
      cust,
      month,
      year,
    };

    return ClientAPI.Get(`invoices/kpi`, params);
  }

  async getAgeingKpi() {
    return ClientAPI.Get("invoices/aging/kpi", {});
  }
}
