import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "grid h-screen" }
const _hoisted_2 = {
  key: 0,
  class: "col-12 lg:col-12"
}
const _hoisted_3 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      (_ctx.dashboard.url)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("iframe", {
              src: _ctx.dashboard.url,
              frameborder: "0",
              style: {"border":"0","width":"100%","height":"100%","overflow":"hidden"},
              allowfullscreen: ""
            }, null, 8, _hoisted_3)
          ]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_Footer)
  ], 64))
}