import { AxiosInstance } from "axios";
import axios from "axios";
import Picker from "@/types/picker";
import ClientAPI from "./ClientAPI";

export default class PickerService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getPicker(id: string, client: string) {
    const params = {
      client,
    };
    return ClientAPI.Get(`picker/${id}`, params);
  }

  async putPicker(id: string, client: string, payload: Picker | null) {
    const body = { ...payload };
    const params = {
      client,
    };
    return ClientAPI.Put(`picker/${id}`, body, params);
  }
}
