import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";

export default class LocationService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getLocations(client: any) {
    const params = {
      client,
    };
    return ClientAPI.Get(`locations`, params);
  }

  async getLocationById(client: any, id: string, fieldnames?: string) {
    const params = {
      client,
      fieldnames,
    };
    return ClientAPI.Get(`location/${id}`, params);
  }
}
