import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import FiltersState from "@/types/state/filters";

const namespaced = true;

export const state: FiltersState = {
  salesOrders: {
    filters: {},
    sort: {
      sortField: "",
      sortOrder: 0,
    },
  },
  salesQuotes: {
    filters: {},
    sort: {
      sortField: "date",
      sortOrder: -1,
    },
  },
  salesOpps: {
    filters: {},
    sort: {
      sortField: "",
      sortOrder: 0,
    },
  },
};

export const getters: GetterTree<FiltersState, RootState> = {
  getSalesOrdersSortFilters: (state) => {
    return state.salesOrders;
  },
  getSalesQuotesSortFilters: (state) => {
    return state.salesQuotes;
  },
  getSalesOppsSortFilters: (state) => {
    return state.salesOpps;
  },
};

export const mutations: MutationTree<FiltersState> = {
  SET_SALES_ORDER_SORT_FILTERS(state, value) {
    state.salesOrders = value;
  },
  SET_SALES_QUOTE_SORT_FILTERS(state, value) {
    state.salesQuotes = value;
  },
  SET_SALES_OPPS_SORT_FILTERS(state, value) {
    state.salesOpps = value;
  },
};

export const actions: ActionTree<FiltersState, RootState> = {
  setSalesOrderSortFilters({ commit }, payload) {
    commit("SET_SALES_ORDER_SORT_FILTERS", payload);
  },
  setSalesQuoteSortFilters({ commit }, payload) {
    commit("SET_SALES_QUOTE_SORT_FILTERS", payload);
  },
  setSalesOppsSortFilters({ commit }, payload) {
    commit("SET_SALES_OPPS_SORT_FILTERS", payload);
  },
};

export const filters: Module<FiltersState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
