import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import SalesState from "@/types/state/sales";

import SOQuotesService from "@/services/SOQuotesService";
import SalesService from "@/services/SalesService";

import { MAX_EXPORT_ROWS } from "@/utility/global";

const quotesService = new SOQuotesService(process.env.VUE_APP_ABSTRACTION_API);
const salesService = new SalesService();

const namespaced = true;

export const state: SalesState = {
  orders: null,
  orderPDFs: [],
  loadingPDFs: [],
  quotes: [],
  itemsPerPage: 10,
  page: 0,
  firstRow: 0,
  rangeStart: 1,
  rangeEnd: 10,
  types: [],
  soMonthlyData: [],
  totalRecords: 0,
  filterValues: {},
  sortOrder: "DEC",
  sortBy: "so_id",
};

export const getters: GetterTree<SalesState, RootState> = {
  getOrders: (state) => {
    return state.orders;
  },
  getSoMonthlyData: (state) => {
    return state.soMonthlyData;
  },
  getQuotes: (state) => {
    return state.quotes;
  },
  getRangeEnd: (state) => {
    return state.rangeEnd;
  },
  getPage: (state) => {
    return state.page;
  },
  getFirstRow: (state) => {
    return state.firstRow;
  },
  getOrdersLength: (state) => {
    return state.orders == null ? 0 : state.orders.length;
  },
  getQuotesLength: (state) => {
    return state.quotes.length;
  },
  getPDFS: (state) => {
    return state.orderPDFs;
  },
  getLoadingPDFs: (state) => {
    return state.loadingPDFs;
  },
  getTotalRecords(state): number {
    return state.totalRecords;
  },
  getSortBy(state): string {
    return state.sortBy;
  },
  getSortOrder(state): string {
    return state.sortOrder;
  },
  getRange(state): number {
    return state.rangeEnd;
  },
  getItemsPerPage(state): number {
    return state.itemsPerPage;
  },
};

export const mutations: MutationTree<SalesState> = {
  SET_ORDER_DATA(state, orders) {
    if (state.orders) {
      state.orders = [...state.orders, ...orders];
    } else {
      state.orders = [...orders];
    }
  },
  SET_SO_MONTHLY_DATA(state, data) {
    state.soMonthlyData = data;
  },
  SET_QUOTE_DATA(state, quotes) {
    state.quotes = quotes;
  },
  CLEAR_QUOTES(state) {
    state.quotes = [];
  },
  CLEAR_ORDERS(state) {
    state.orders = null;
    state.totalRecords = 0;
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  SET_RANGE_START(state, rangeStart) {
    state.rangeStart = rangeStart;
  },
  SET_RANGE_END(state, rangeEnd) {
    state.rangeEnd = rangeEnd;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_ORDER(state, sortOrder) {
    state.sortOrder = sortOrder;
  },
  SET_FILTER_VALUES(state, filterValues) {
    state.filterValues = filterValues;
  },
  SET_TOTAL_RECORDS(state, totalRecords) {
    state.totalRecords = totalRecords;
  },
  PRINT_EXPORT_RANGE(state) {
    state.rangeStart = 1;
    state.rangeEnd = MAX_EXPORT_ROWS;
  },
  SET_ITEMS_PER_PAGE(state, items) {
    state.itemsPerPage = items;
  },
  SET_FIRST_ROW(state, row) {
    state.firstRow = row;
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 10;
  },
  ADD_PDF(state, pdf) {
    state.orderPDFs.push(pdf);
  },
  REMOVE_PDF_BY_ID(state, id) {
    state.orderPDFs = state.orderPDFs.filter((item) => item.id !== id);
  },
  ADD_PDF_LOADING(state, id) {
    state.loadingPDFs.push(id);
  },
  REMOVE_PDF_LOADING(state, id) {
    state.loadingPDFs = state.loadingPDFs.filter((item) => item !== id);
  },
};

export const actions: ActionTree<SalesState, RootState> = {
  fetchQuotes({ state, commit, dispatch }, { cust, status, id, correls }) {
    return new Promise((resolve, reject) => {
      quotesService
        .getSOQuotes(cust, status, id, correls)
        .then((response: any) => {
          commit("SET_QUOTE_DATA", response);
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  setFirstRow({ commit }, row) {
    commit("SET_FIRST_ROW", row);
  },
  async fetchSoMonthlyData({ commit }, { cust, month, year }) {
    salesService.getKpiInfo(cust, month, year).then((response: any) => {
      commit("SET_SO_MONTHLY_DATA", response.data);
    });
  },
  fetchOrders(
    { state, commit, dispatch },
    { custId, id, status, correls, date },
  ) {
    return new Promise((resolve, reject) => {
      salesService
        .getOrders(custId, id, status, correls, date)
        .then((response: any) => {
          const so_items = response.so_items;
          for (let i = 0; i < so_items.length; i++) {
            // for proper datatable sort by date, date strings are converted to date objects
            so_items[i].date =
              so_items[i].date != null
                ? (new Date(so_items[i].date) as unknown as string)
                : "";
          }
          commit("SET_ORDER_DATA", so_items);
          resolve({ success: true });
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `Error Getting Orders. ${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false, error: error });
        });
    });
  },
  async fetchOrdersRecords(
    { state, commit, dispatch },
    {
      custs,
      ids,
      status,
      sortBy,
      sortOrder,
      dateStart,
      dateEnd,
      correl_criteria,
      addOrder,
      correls,
      filename,
      rep,
      disableSortedSample,
      user,
      type,
      forPrintExport,
    },
  ) {
    return new Promise((resolve, reject) => {
      if (!addOrder) {
        commit("RESET_PAGE");
      }
      if (forPrintExport) {
        commit("PRINT_EXPORT_RANGE");
      }
      salesService
        .getOrdersRecords(
          custs,
          ids,
          status,
          state.rangeStart,
          state.rangeEnd,
          sortBy,
          sortOrder,
          dateStart,
          dateEnd,
          correls,
          filename,
          correl_criteria,
          rep,
          user,
          type,
        )
        .then((response: any) => {
          const so_items = response.so_items;
          commit("SET_TOTAL_RECORDS", parseInt(response.total_records_found));
          // if(!addOrder){
          //   commit("CLEAR_ORDERS");
          //   commit("SET_FIRST_ROW", 0);
          // }
          for (let i = 0; i < so_items.length; i++) {
            // for proper datatable sort by date, date strings are converted to date objects
            so_items[i].date =
              so_items[i].date != null
                ? (new Date(so_items[i].date) as unknown as string)
                : "";
          }

          resolve({ success: true, data: so_items });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  updateQuote({ state, dispatch }, payload) {
    return new Promise((resolve, reject) => {
      quotesService
        .putSOQuote(payload)
        .then(() => {
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  updateOrder({ state, commit, dispatch }, { orderId, payload }) {
    return new Promise((resolve, reject) => {
      salesService
        .putOrder(orderId, payload)
        .then((response) => {
          commit("REMOVE_PDF_BY_ID", orderId);
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  createQuote({ commit, dispatch }, { payload }) {
    return new Promise((resolve, reject) => {
      quotesService
        .postSOQuote(payload)
        .then((response: any) => {
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  createOrder({ commit, dispatch }, { payload }) {
    return new Promise((resolve, reject) => {
      salesService
        .postOrder(payload)
        .then((response) => {
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  clearAndResetOrders({ commit }) {
    commit("CLEAR_ORDERS");
    // commit("RESET_PAGE");
  },
  async getOrderPDF({ commit, dispatch, state }, payload) {
    const pdf = state.orderPDFs.find((i) => i.id === payload.recordId);
    if (pdf?.pdf) {
      window.open(pdf.pdf, "_blank");
    } else {
      commit("ADD_PDF_LOADING", payload.recordId);
      await salesService
        .getOrderPDF(payload.recordId, payload.Client)
        .then((resp: any) => {
          const bufferArray = base64ToArrayBuffer(resp);
          const blobStore = new Blob([bufferArray], {
            type: "application/pdf",
          });
          const data = window.URL.createObjectURL(blobStore);
          window.open(data, "_blank");
          commit("ADD_PDF", { id: payload.recordId, pdf: data });
          commit("REMOVE_PDF_LOADING", payload.recordId);
        })
        .catch((error) => {
          commit("REMOVE_PDF_LOADING", payload.recordId);
        });
    }
  },
};

export const sales: Module<SalesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

// Move to utils file
function base64ToArrayBuffer(data: string) {
  const bString = window.atob(data);
  const bLength = bString.length;
  const bytes = new Uint8Array(bLength);
  for (let i = 0; i < bLength; i++) {
    const ascii = bString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes;
}
