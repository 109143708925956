import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";

const namespaced = true;

export interface AccountingFormState {
  activeTab: number;
}

export const state: AccountingFormState = {
  activeTab: 0,
};

export const getters: GetterTree<AccountingFormState, RootState> = {
  getActiveTab: (state) => {
    return state.activeTab;
  },
};

export const mutations: MutationTree<AccountingFormState> = {
  SET_ACTIVE_TAB(state, index) {
    state.activeTab = index;
  },
};

export const actions: ActionTree<AccountingFormState, RootState> = {
  setActiveTab({ commit }, index) {
    commit("SET_ACTIVE_TAB", index);
  },
};

export const accountingForm: Module<AccountingFormState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
