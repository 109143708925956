import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import LaborControlState from "@/types/controls/labor";
import ControlService from "@/services/ControlService";

const namespaced = true;
const controlService = new ControlService();

export const state: LaborControlState = {
  auto_restart: "",
  control_id: "",
  function_type_items: [],
  rate_type_items: [],
  no_gl_impact: "",
  default_start_job_labor_function: "",
};

export const getters: GetterTree<LaborControlState, RootState> = {
  getFunctionTypes: (state) => {
    return state.function_type_items;
  },
  getDefaultStartJobFunction: (state) => {
    return state.default_start_job_labor_function;
  },
};

export const mutations: MutationTree<LaborControlState> = {
  SET_DATA(
    state,
    {
      function_type_items,
      rate_type_items,
      control_id,
      auto_restart,
      no_gl_impact,
      default_start_job_labor_function,
    },
  ) {
    state.function_type_items = function_type_items;
    state.rate_type_items = rate_type_items;
    state.control_id = control_id;
    state.auto_restart = auto_restart;
    state.no_gl_impact = no_gl_impact;
    state.default_start_job_labor_function = default_start_job_labor_function;
  },
};

export const actions: ActionTree<LaborControlState, RootState> = {
  async fetchLaborControl({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.control_id) {
        resolve(state);
      } else {
        controlService
          .getControl("LABOR", "LABOR.CONTROL", "CONTROL", "")
          .then((response: any) => {
            if (response.length > 0) {
              commit("SET_DATA", {
                function_type_items: response[0].function_type_items,
                rate_type_items: response[0].rate_type_items,
                control_id: response[0].control_id,
                auto_restart: response[0].auto_restart,
                no_gl_impact: response[0].no_gl_impact,
                default_start_job_labor_function:
                  response[0].default_start_job_labor_function,
              });
              resolve(response[0]);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const laborControl: Module<LaborControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
