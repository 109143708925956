import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import BillingState from "@/types/state/billing";
import BillingService from "@/services/BillingService";
import TicketService from "@/services/TicketService";
import PartsService from "@/services/PartsService";
const namespaced = true;
import ControlService from "@/services/ControlService";
const ticketService = new TicketService(process.env.VUE_APP_ABSTRACTION_API);
const controlService = new ControlService();
const billingService = new BillingService(process.env.VUE_APP_ABSTRACTION_API);
const partsService = new PartsService();

export const state: BillingState = {
  tickets: [],
  billingTypeItems: [],
  custTypeItems: [],
  parts: [],
  billing: [],
  ticketsPerPage: 5,
  page: 1,
  rangeStart: 1,
  rangeEnd: 100,
  currentIndex: -1,
  monthlyData: [],
};

export const getters: GetterTree<BillingState, RootState> = {
  getTickets: (state) => {
    return state.tickets;
  },
  getBills: (state) => {
    return state.billing;
  },
  getBillingTypeItems: (state) => {
    return state.billingTypeItems;
  },
  getCustTypeItems: (state) => {
    return state.custTypeItems;
  },
  getParts: (state) => {
    return state.parts;
  },
  getRange(state): number {
    return state.rangeEnd;
  },
  getPage(state): number {
    return state.page;
  },
  getLength(state): number {
    return state.tickets.length;
  },
  getCurrentIndex(state): number {
    return state.currentIndex;
  },
  getMonthlyData: (state) => {
    return state.monthlyData;
  },
};

export const mutations: MutationTree<BillingState> = {
  SET_TICKETS(state, tickets) {
    state.tickets.push(...tickets);
  },
  SET_TICKET(state, ticket) {
    state.tickets.unshift(ticket);
  },
  SET_BILLING_DATA(state, { billingData, custData }) {
    state.billingTypeItems = billingData;
    state.custTypeItems = custData;
  },
  SET_Bills(state, billing) {
    state.billing.push(billing);
  },
  UPDATE_TICKET(state, ticket) {
    const ticketIndex = state.tickets.findIndex((existingTicket) => {
      return existingTicket.id == ticket.id;
    });
    state.tickets[ticketIndex] = ticket;
  },
  CLEAR_TICKETS(state) {
    state.tickets = [];
  },
  SET_PARTS(state, parts) {
    state.parts = parts;
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  SET_ITEMS_PER_PAGE(state, items) {
    state.ticketsPerPage = items;
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  SET_CURR_INDEX(state, index) {
    state.currentIndex = index;
  },
  SET_MONTHLY_DATA(state, data) {
    state.monthlyData = data;
  },
};

export const actions: ActionTree<BillingState, RootState> = {
  async getTickets(
    { commit, state },
    {
      custs,
      ids,
      status,
      addTicket,
      assignee,
      assignees,
      sortBy,
      sortOrder,
      correls,
      types,
    },
  ) {
    if (!addTicket) {
      commit("RESET_PAGE");
    }
    return new Promise((resolve, reject) => {
      ticketService
        .getTicketsSearch(
          state.rangeStart,
          state.rangeEnd,
          custs,
          ids,
          status,
          assignee,
          assignees,
          sortBy,
          sortOrder,
          correls,
          types,
        )
        .then((response: any) => {
          if (!addTicket) {
            commit("CLEAR_TICKETS");
          }
          commit("SET_TICKETS", response.ticket_items);
          commit("NEXT_RANGE");
          resolve({ success: true, data: response.ticket_items });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  async fetchMonthlyData({ commit }, { cust, month, year }) {
    ticketService
      .getKpiInfo(cust, month, year)
      .then((response: any) => {
        commit("SET_MONTHLY_DATA", response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  },
  async addTicket({ commit, dispatch }, payload) {
    const { ticket } = payload;

    return new Promise((resolve, reject) => {
      ticketService
        .newTicket(ticket)
        .then((response: any) => {
          dispatch(
            "notification/add",
            {
              message: `Successfully Created Ticket ID ${response.recordId}`,
              type: "success",
            },
            { root: true },
          );
          resolve({ success: true, ticketId: response.recordId });
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  async updateTicket({ dispatch }, payload) {
    return new Promise((resolve, reject) => {
      ticketService
        .updateTicket(payload)
        .then((response) => {
          dispatch(
            "notification/add",
            {
              message: `Successfully updated Ticket ${
                (response as any).recordId
              }`,
              type: "success",
            },
            { root: true },
          );
          resolve(response);
        })
        .catch((error) => {
          reject({ success: false, error: error });
        });
    });
  },
  async getControls({ commit, dispatch }, payload) {
    if (payload.fieldnames === null) {
      payload.fieldnames = "";
    }
    await controlService
      .getControl(
        payload.id,
        payload.procedure,
        payload.filename,
        payload.fieldnames,
        payload.Client,
      )
      .then((response: any) => {
        switch (payload.id) {
          case "BILL":
            commit("SET_BILLING_DATA", {
              billingData: response[0].billing_type_items,
              custData: response[0].cust_type_items,
            });
            break;
          default:
            break;
        }
      });
  },

  async postBillingInfo({ commit, dispatch, state }, { payload }) {
    return new Promise((resolve, reject) => {
      billingService
        .postBillingInfo(payload)
        .then((response) => {
          const notification = {
            type: "success",
            message: "Time Entered Successfully",
          };
          dispatch("notification/add", notification, { root: true });
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getBills({ commit, state }, { ticket }) {
    return new Promise((resolve, reject) => {
      billingService
        .getbills(ticket)
        .then((response: any) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  async fetchParts({ commit }, { fieldnames }) {
    partsService.getParts(fieldnames).then((response: any) => {
      commit("SET_PARTS", response.parts_items);
    });
  },
  updateTicketRecord({ commit }, ticket) {
    commit("UPDATE_TICKET", ticket);
  },
  addTicketRecord({ commit }, ticket) {
    commit("SET_TICKET", ticket);
  },
  updateCurrentIndex({ commit }, index) {
    commit("SET_CURR_INDEX", index);
  },
  setItemsPerPage({ commit }, items) {
    commit("SET_ITEMS_PER_PAGE", items);
  },
  nextPage({ commit }, page) {
    commit("NEXT_PAGE", page);
  },
};

export const billing: Module<BillingState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
