import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import StaxService from "@/services/StaxService";

const namespaced = true;
const staxService = new StaxService();

interface StaxState {
  stax_items: Array<any>;
}

export const state: StaxState = {
  stax_items: [],
};

export const getters: GetterTree<StaxState, RootState> = {
  getSalesTaxCodes: (state) => {
    return state.stax_items;
  },
};

export const mutations: MutationTree<StaxState> = {
  SET_DATA(state, stax_items) {
    state.stax_items = stax_items;
  },
};

export const actions: ActionTree<StaxState, RootState> = {
  async fetchSalesTaxCodes({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.stax_items.length > 0) {
        resolve(state);
      } else {
        staxService
          .getStax("")
          .then((response: any) => {
            if (response.stax_items?.length > 0) {
              commit("SET_DATA", response.stax_items || []);
              resolve(response);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const stax: Module<StaxState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
