import InventoryModuleState, {
  ReceiptsObject,
} from "@/types/state/inventoryModule";
import { RootState } from "@/types/state";
import { ActionTree, GetterTree, Module, MutationTree } from "vuex";
import Receipts, { ReceiptQuantityItem } from "@/types/receipts";
import { cloneDeep } from "lodash";
import ReceiptsService, {
  ReceiptsMetaData,
  ReceiptsPostRequest,
} from "@/services/ReceiptsService";
import Utils from "@/utility/utils";
import PO from "@/types/po";
const namespaced = true;

const receiptsService = new ReceiptsService();

export const state: InventoryModuleState = {
  ReceiptsMap: [],
  activeTab: 0,
};

export const getters: GetterTree<InventoryModuleState, RootState> = {
  getReceipts(state) {
    return state.ReceiptsMap;
  },
  getActiveReceipts(state) {
    return state.ReceiptsMap[state.activeTab - 1];
  },
  getActiveTab: (state) => {
    return state.activeTab;
  },
};

export const mutations: MutationTree<InventoryModuleState> = {
  ADD_RECEIPT(
    state,
    {
      receipts,
      metaData,
      po,
    }: { receipts: Receipts; metaData: ReceiptsMetaData; po?: PO },
  ) {
    const receipt: ReceiptsObject = {
      old_record: cloneDeep(receipts),
      record: cloneDeep(receipts),
      id: (crypto as any).randomUUID() as string,
      metaData: metaData,
      po: po,
    };
    state.ReceiptsMap = [receipt, ...state.ReceiptsMap];
  },
  REMOVE_RECEIPT(state, id: string) {
    state.ReceiptsMap = state.ReceiptsMap.filter((map) => map.id !== id);
  },
  UPDATE_RECEIPTS(
    state,
    { receipts, id, po }: { receipts: Receipts; id: string; po?: PO },
  ) {
    const receiptMap = state.ReceiptsMap.find((map) => map.id === id);

    if (receiptMap) {
      if (receiptMap.metaData.po_number != po?.po_id) {
        receiptMap.metaData.po_number = po?.po_id || "";
      }
      if (
        receipts.receipts_id &&
        receiptMap.old_record.receipts_id != receipts.receipts_id
      ) {
        receiptMap.old_record = cloneDeep(receipts);
      }
      receiptMap.record = cloneDeep(receipts);
      receiptMap.po = po;
      state.ReceiptsMap = [...state.ReceiptsMap];
    } else {
      const receipt: ReceiptsObject = {
        old_record: cloneDeep(receipts),
        record: cloneDeep(receipts),
        id: (crypto as any).randomUUID() as string,
        metaData: {
          po_number: po?.po_id || "",
        },
        po: po,
      };
      state.ReceiptsMap = [receipt, ...state.ReceiptsMap];
    }
  },
  SET_ACTIVE_TAB(state, tab) {
    state.activeTab = tab;
  },
};

export const actions: ActionTree<InventoryModuleState, RootState> = {
  setActiveTab({ commit }, tab) {
    commit("SET_ACTIVE_TAB", tab);
  },
  changeActiveTab({ commit }, tabIndex: number) {
    commit("SET_ACTIVE_TAB", tabIndex);
  },
  addReceipts(context) {
    const receipts = new Receipts();
    context.commit("ADD_RECEIPT", { receipts, metaData: { po_number: "" } });
    context.commit("SET_ACTIVE_TAB", context.state.activeTab + 1);
  },
  updateReceiptsById(
    context,
    { receipts, id, po }: { receipts: Receipts; id: string; po: PO },
  ) {
    context.commit("UPDATE_RECEIPTS", { receipts, id, po });
  },
  removeReceipts(context, id: number) {
    context.commit("REMOVE_RECEIPT", id);
    context.commit("SET_ACTIVE_TAB", 0);
  },
  async createReceiptsById(
    context,
    { receipts, id }: { receipts: Receipts; id: string },
  ) {
    const receiptMap = context.state.ReceiptsMap.find((map) => map.id === id);

    try {
      const receiptsRequest: ReceiptsPostRequest = {
        record: receipts,
        metaData: JSON.stringify(receiptMap?.metaData),
      };
      const response = await receiptsService.createReceipts(receiptsRequest);

      if (response.response.status != "success") {
        return response.response;
      }

      const { record } = response.response;
      const newReceipts = new Receipts();
      newReceipts.initFromReceipts(record);
      context.commit("UPDATE_RECEIPTS", {
        receipts: newReceipts,
        id,
        po: receiptMap?.po,
      });

      return response.response;
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  },
  async postReceiptsById(
    context,
    { receipts, id }: { receipts: Receipts; id: string },
  ) {
    const receiptMap = context.state.ReceiptsMap.find((map) => map.id === id);

    try {
      const receiptsRequest: ReceiptsPostRequest = {
        record: receipts,
        oldRecord: receiptMap?.old_record,
        metaData: JSON.stringify(receiptMap?.metaData),
      };
      const response = await receiptsService.updateReceiptsById(
        receipts.receipts_id,
        receiptsRequest,
      );

      if (response.response.status != "success") {
        return response.response;
      }

      const { record } = response.response;
      const newReceipts = new Receipts();
      newReceipts.initFromReceipts(record);
      context.commit("UPDATE_RECEIPTS", {
        receipts: newReceipts,
        id,
        po: receiptMap?.po,
      });

      return response.response;
    } catch (error: any) {
      return { error: Utils.parseErrorMessage(error) };
    }
  },
  async createReceiptsFromPurchaseOrder(
    context,
    { po, id }: { po: PO; id: string },
  ) {
    try {
      const response = await receiptsService.createReceiptsFromPurchaseOrder(
        po.po_id,
      );

      if (response.response.status != "success") {
        throw new Error("Failed to create receipt from purchase order");
      }

      const { record, metaData } = response.response;

      const receipts = new Receipts();
      receipts.initFromReceipts(record);

      receipts.li_no_items.forEach((item) => {
        if (!item.receipt_qty_items) {
          item.receipt_qty_items = [new ReceiptQuantityItem()];
        }
      });

      if (!receipts.rec_date) {
        receipts.rec_date = Utils.roverDateString(new Date());
      }

      receipts.vendor_name = metaData?.vendor_name;
      receipts.addScheduleLineItem(metaData?.line_items);

      if (id) {
        context.commit("UPDATE_RECEIPTS", {
          receipts,
          id,
          po,
        });
      } else {
        context.commit("ADD_RECEIPT", {
          receipts,
          metaData: { po_number: po.po_id },
          po,
        });
        context.commit("SET_ACTIVE_TAB", 1);
      }
      return response.response;
    } catch (error) {
      return { error: Utils.parseErrorMessage(error) };
    }
  },
};

export const inventoryModule: Module<InventoryModuleState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
