import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import SaleOppsState from "@/types/state/saleOpps";

import SaleOppsService from "@/services/SaleOppsService";

const namespaced = true;

const saleOppsService = new SaleOppsService(
  process.env.VUE_APP_ABSTRACTION_API,
);

export const state: SaleOppsState = {
  saleOpps: [],
  page: 1,
  firstRow: 0,
  rangeStart: 1,
  rangeEnd: 100,
};

export const getters: GetterTree<SaleOppsState, RootState> = {
  getSaleOpps: (state) => {
    return state.saleOpps;
  },
  getRangeStart: (state) => {
    return state.rangeStart;
  },
  getRangeEnd: (state) => {
    return state.rangeEnd;
  },
  getFirstRow: (state) => {
    return state.firstRow;
  },
  getCurrPage: (state) => {
    return state.page;
  },
};

export const mutations: MutationTree<SaleOppsState> = {
  SET_FIRST_ROW(state, row) {
    state.firstRow = row;
  },
  SET_NEXT_PAGE(state, page) {
    state.page = page;
  },
  SET_NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  CLEAR_SALE_OPPS(state) {
    state.saleOpps = [];
  },
};

export const actions: ActionTree<SaleOppsState, RootState> = {
  fetchSaleOpps({ commit, dispatch }, payload) {
    const {
      ids,
      custs,
      rangeStart,
      rangeEnd,
      status,
      assignees,
      dateStart,
      dateEnd,
      isAddingSaleOpps,
      sortBy,
      sortOrder,
      correls,
    } = payload;

    const params = {
      ids,
      custs,
      rangeStart,
      rangeEnd,
      status,
      assignees,
      dateStart,
      dateEnd,
      sortBy,
      sortOrder,
      correls,
    };

    return new Promise((resolve, reject) => {
      if (!isAddingSaleOpps) {
        commit("RESET_PAGE");
      }
      saleOppsService
        .getSaleOpps(
          ids,
          rangeStart,
          rangeEnd,
          dateStart,
          dateEnd,
          assignees,
          status,
          sortBy,
          sortOrder,
          custs,
          correls,
        )
        .then((response: any) => {
          if (!isAddingSaleOpps) {
            commit("CLEAR_SALE_OPPS");
            commit("SET_FIRST_ROW", 0);
          }
          for (let i = 0; i < response.saleopp_items.length; i++) {
            // for proper datatable sort by date, date strings are converted to date objects
            response.saleopp_items[i].date =
              response.saleopp_items[i].date != null
                ? (new Date(
                    response.saleopp_items[i].date,
                  ) as unknown as string)
                : "";
          }
          commit("SET_NEXT_RANGE");
          resolve({ success: true, data: response.saleopp_items });
        })
        .catch((error) => {
          dispatch(
            "notification/add",
            {
              message: `${error.message}.`,
              type: "error",
            },
            { root: true },
          );
          reject({ success: false });
        });
    });
  },
  setFirstRow({ commit }, row) {
    commit("SET_FIRST_ROW", row);
  },
  clearAndResetOpps({ commit }) {
    commit("CLEAR_SALE_OPPS");
    commit("RESET_PAGE");
  },
};

export const saleOpps: Module<SaleOppsState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
