import ClientAPI from "./ClientAPI";

export default class AccountsPayableService {
  async getAccountsPayables(
    id?: string,
    po?: string,
    status?: string,
    rangeStart?: number,
    rangeEnd?: number,
    vendorId?: string,
    dueDateStart?: string,
    dueDateEnd?: string,
    sortBy?: string,
    sortOrder?: string,
    correls?: string,
    type?: string,
  ) {
    const params = {
      id,
      po,
      status,
      rangeStart,
      rangeEnd,
      vendorId,
      dueDateStart,
      dueDateEnd,
      sortBy,
      sortOrder,
      correls,
      type,
    };
    return ClientAPI.Get(`ap`, params);
  }

  async getAccountsPayablesKpi() {
    return ClientAPI.Get("ap/aging/kpi", {});
  }
}
