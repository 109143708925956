import axios, { AxiosInstance } from "axios";
import ClientAPI from "./ClientAPI";

interface GetWorkCenterRequest {
  allowSchedule?: boolean;
  rangeStart?: string;
  rangeEnd?: string;
  fieldNames?: string;
}

export default class WorkCenterService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getWorkCenters({
    allowSchedule,
    rangeStart,
    rangeEnd,
    fieldNames,
  }: GetWorkCenterRequest) {
    const params = {
      allowSchedule,
      rangeStart,
      rangeEnd,
      fieldNames,
    };
    return ClientAPI.Get("workcenter", params);
  }
}
