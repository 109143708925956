import { GeneralLedgerLineItem } from "@/types/state/generalLedger";
import ClientAPI from "./ClientAPI";
import ApiService from "./ApiService";

type GlTransactionRequest = {
  fyear?: string;
  date?: string;
  desc?: string;
  journal?: string;
  reverse_year?: string;
  reverse_date?: string;
  lines_items?: GlTransactionLineItem[];
};

type GlTransactionLineItem = {
  lines?: string;
  accts?: string;
  amounts?: string;
  line_notes?: string;
};

export default class GlChartService extends ApiService {
  constructor() {
    super();
  }
  async getAccounts(fieldnames: any, client: any) {
    return ClientAPI.Get(`GlChart/accounts`, {
      fieldnames: fieldnames,
      Client: client,
    });
  }

  async getTrialBalance(trialBalanceRequest?: {
    year?: string;
    month?: string;
  }) {
    return ClientAPI.Get(`GlChart/trialbalance`, trialBalanceRequest);
  }

  async getAccountActivity(accountActivityRequest: {
    account: string;
    rangeStart?: number;
    rangeEnd?: number;
    includeUnposted?: string;
  }) {
    return ClientAPI.Get(`GlChart/accountactivity`, accountActivityRequest);
  }

  async getMonthEndProgressKpi() {
    return this.Get(`GlChart/monthEndProgressKpi`, {});
  }

  async getGeneralLedgerTransactions(params: any) {
    return this.Get(`GlTrans/transactions`, params);
  }

  async postGeneralLedgerEntry(entry: any) {
    const newTransaction = entry.newTransaction;
    const glTransRequest: GlTransactionRequest = {
      fyear: new Date(newTransaction.fiscalYear, 1).getFullYear().toString(),
      date: new Date(newTransaction.date).toLocaleDateString("en-US"),
      desc: newTransaction.desc,
      journal: newTransaction.refJournal,
      reverse_year: newTransaction.yearToReverse
        ? new Date(newTransaction.yearToReverse, 1).getFullYear().toString()
        : "",
      reverse_date: newTransaction.dateToReverse
        ? new Date(newTransaction.dateToReverse).toLocaleDateString("en-US")
        : "",
      lines_items: newTransaction.lineItems.map(
        (item: GeneralLedgerLineItem) => {
          return {
            lines: "" + item.li,
            accts: item.account,
            amounts: +item.debit ? "" + item.debit : "" + +item.credit * -1,
            line_notes: item.notes,
          };
        },
      ),
    };

    return this.Put(`GlTrans/transactions`, { newTransaction: glTransRequest });
  }

  async postInterfaceRegister(payload: {
    startDate?: string;
    endDate?: string;
    modules?: string;
  }) {
    return this.Post(`GlTrans/interfaceRegisters`, payload);
  }

  async getRegisters(payload: {
    startDate?: string;
    endDate?: string;
    registerFile?: string;
  }) {
    return this.Get(`GlTrans/registers`, payload);
  }

  async glPost(payload: {
    startDate?: string;
    endDate?: string;
    glTransList?: string;
    journalTypes?: string;
  }) {
    return this.Post(`GlTrans/postGl`, payload);
  }
}
