import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import { Scan } from "@/types/state/session";
const namespaced = true;

export const state: Scan = {
  suffix: "",
  prefix: "",
  scanMode: false,
  quickScan: false,
  userIdAsEmployeeId: false,
};

export const getters: GetterTree<Scan, RootState> = {
  getScanSettings(state): any {
    return state;
  },
  getQuickScan(state): boolean {
    return state.quickScan;
  },
  getUserIdAsEmployeeId(state): boolean {
    return state.userIdAsEmployeeId;
  },
};

export const mutations: MutationTree<Scan> = {
  SET_SCAN_SUFFIX(state, suffix) {
    state.suffix = suffix;
  },
  SET_SCAN_PREFIX(state, prefix) {
    state.prefix = prefix;
  },
  SET_SCAN_MODE(state, mode) {
    state.scanMode = mode;
  },
  SET_QUICK_SCAN(state, quickScan) {
    state.quickScan = quickScan;
  },
  SET_USER_ID_AS_EMPLOYEE_ID(state, userIdAsEmployeeId) {
    state.userIdAsEmployeeId = userIdAsEmployeeId;
  },
};

export const actions: ActionTree<Scan, RootState> = {
  setScanSuffix({ commit }, suffix) {
    commit("SET_SCAN_SUFFIX", suffix);
  },
  setScanPrefix({ commit }, prefix) {
    commit("SET_SCAN_PREFIX", prefix);
  },
  setScanMode({ commit }, mode) {
    commit("SET_SCAN_MODE", mode);
  },
  setQuickScan({ commit }, quickScan) {
    commit("SET_QUICK_SCAN", quickScan);
  },
  setUserIdAsEmployeeId({ commit }, userIdAsEmployeeId) {
    commit("SET_USER_ID_AS_EMPLOYEE_ID", userIdAsEmployeeId);
  },
};

export const scan: Module<Scan, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
