import { assign } from "lodash";
import Utils from "@/utility/utils";

export default class Receipts {
  receipts_id = "";
  rec_date = Utils.roverDateString(new Date());
  packing_slip = "";
  orig_receipt = "";
  container_id = "";
  attachments_items = [] as Array<Attachment>;
  mship_id = "";
  frt_unbilled = "";
  create_dmr = "";
  debit_receipt_items = [] as Array<DebitReceiptItem>;
  li_no_items = [] as Array<LineNumberItem>;
  post_date = "";
  phantom_li_items = [] as Array<PhantomLineItem>;
  posted_by = "";
  rec_time = "";
  vendor = "";
  vendor_name = ""; // correlative
  status = "";
  ship_debit = "";
  received_by = "";
  vendor_rma_no = "";

  initFromReceipts(receipts: Receipts): Receipts {
    return assign(this, receipts);
  }

  addScheduleLineItem(line_items: any) {
    this.li_no_items.forEach((line) => {
      const lineItem = line_items?.find(
        (item: any) => item.line_no === line.li_no,
      );
      if (lineItem) {
        line.schedule_items = lineItem.schedule;
      }
    });
  }
}

export interface Attachment {
  attachments: string;
}

export interface DebitReceiptItem {
  debit_receipt: string;
}

export class LineNumberItem {
  li_no = "";
  buy_um = "";
  buy_factor = "";
  account_no = "";
  bin_items = [] as Array<BinItem>;
  debit_qty = "";
  close_li = "";
  expedite = "";
  dmr_id = "";
  desc_items = [] as Array<DescriptionItem>;
  exception_code = "";
  li_qty = "";
  part_no = "";
  notes = "";
  li_std_cost = "";
  li_std_moh = "";
  li_std_lot_size = "";
  li_std_frt_cost = "";
  rev = "";
  rec_loc = "";
  price_per = "";
  receipt_qty_items = [] as Array<ReceiptQuantityItem>;
  stock_loc = "";
  ship_to_items = [] as Array<ShipToItem>;
  sched_var_days_items = [] as Array<ScheduledVarianceDaysItem>;
  unit_price = "";
  unit_est_frt = "";
  wo_id = "";
  weighting_code = "";
  vend_part_no_items = [] as Array<VendorPartNoItem>;
  wo_oper = "";
  schedule_items = [] as Array<ScheduleLineItems>;
}

export interface BinItem {
  bin: string;
  bin_qty: string;
}

export interface DescriptionItem {
  desc: string;
}

export class ReceiptQuantityItem {
  receipt_qty = "";
  it_id = "";
  bin_number = "";
  lot_no = "";
  vendor_lot_no = "";
}

export interface ShipToItem {
  ship_to: string;
}

export interface ScheduledVarianceDaysItem {
  sched_var_days: string;
  sched_var_qty: string;
}

export interface VendorPartNoItem {
  vend_part_no: string;
}

export interface PhantomLineItem {
  phantom_li: string;
  comp_li_items: Array<CompLineItem>;
}

export interface CompLineItem {
  comp_li: string;
  comp_invloc: string;
  comp_bin: string;
  com_std_lotsize: string;
  comp_base_qty: string;
  comp_debit_qty: string;
  comp_it_id: string;
  comp_part: string;
  comp_lot: string;
  comp_notes: string;
  comp_qty: string;
  comp_vend_lot: string;
  comp_std_cost: string;
  comp_std_moh: string;
  comp_std_frt: string;
  comp_unit_frt: string;
}

export interface ScheduleLineItems {
  date: string;
  quantity: string;
}
