import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import PrintControlState from "@/types/controls/printQueues";
import ControlService from "@/services/ControlService";

const namespaced = true;
const controlService = new ControlService();

export const state: PrintControlState = {
  serverPrinters: [],
};

export const getters: GetterTree<PrintControlState, RootState> = {
  getPrinterQueues(state) {
    return state.serverPrinters;
  },
};

export const mutations: MutationTree<PrintControlState> = {
  SET_PRINTER_QUEUES(state, printers) {
    state.serverPrinters = printers;
  },
};

export const actions: ActionTree<PrintControlState, RootState> = {
  async getPrintControl({ commit, state }, { client, refresh }) {
    return new Promise((resolve, reject) => {
      if (state.serverPrinters?.length > 0 && !refresh) {
        resolve(state);
      } else {
        controlService
          .getControl("PRINT.QUEUES", "PRINTER.CONTROL", "CONTROL", "", client)
          .then((resp: any) => {
            if (resp && resp.length > 0) {
              if (resp.length > 0) {
                commit("SET_PRINTER_QUEUES", {
                  serverPrinters: resp[0].svr_path_name_items || [],
                });
                resolve(resp[0]);
              } else {
                reject("No data returned");
              }
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const printControl: Module<PrintControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
