import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import UsersService from "@/services/UserService";
import UsersState from "@/types/users";

const namespaced = true;
const usersService = new UsersService();

export const state: UsersState = {
  users: [],
};

export const getters: GetterTree<UsersState, RootState> = {
  getUsers: (state) => {
    return state.users;
  },
};

export const mutations: MutationTree<UsersState> = {
  SET_USERS(state, users) {
    state.users = users;
    const value: any = { user_id: "", user_name: "Unassigned" };
    state.users.push(value);
  },
};

export const actions: ActionTree<UsersState, RootState> = {
  async fetchUsers({ commit, state }, { refresh }) {
    return new Promise((resolve, reject) => {
      if (state.users && state.users.length > 0 && !refresh) {
        resolve(state);
      } else {
        usersService
          .getUsers()
          .then((resp: any) => {
            commit("SET_USERS", resp.control_items);
            resolve(resp);
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const users: Module<UsersState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
