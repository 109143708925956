import { Module, ActionTree, GetterTree, MutationTree, Getter } from "vuex";
import { RootState } from "@/types/state";
import SideMenuState from "@/types/state/sidemenu";

const namespaced = true;

const SIDE_BAR_WIDTH = 210;
const SIDE_BAR_COLLAPSED_WIDTH = 50;

export const state: SideMenuState = {
  floatingSidebar: false,
  collapsed: true,
  sidebarWidth: SIDE_BAR_COLLAPSED_WIDTH,
  defaultLandingPage: null,
  links: [
    {
      label: "Dashboards",
      icon: "pi pi-fw pi-chart-bar",
      to: "/dashboard",
      imgIcon: "dashboard 64x64.png",
    },
    {
      label: "Customers",
      icon: "pi pi-fw pi-comments",
      to: "/customers",
      imgIcon: "customer 64x64.png",
      dataTestId: "customers-side-menu-link",
    },
    {
      label: "Tickets & Time",
      icon: "pi pi-fw pi-money-bill",
      to: "/tickets",
      imgIcon: "ticket + time 64x64.png",
    },
    {
      label: "Sales",
      icon: "pi pi-fw pi-dollar",
      to: "/sales",
      imgIcon: "sales 64x64.png",
    },
    {
      label: "Manufacturing",
      icon: "pi pi-fw pi-box",
      to: "/manufacturing",
      imgIcon: "manufacturing 64x64.png",
    },
    // {
    //   label: "Parts",
    //   icon: "pi pi-fw pi-briefcase",
    //   to: "/parts",
    // },
    // {
    {
      label: "Inventory",
      icon: "pi pi-fw pi-folder",
      to: "/inventory",
      imgIcon: "inventory 64x64.png",
    },
    {
      label: "Scan",
      icon: "pi pi-fw pi-map",
      to: "/scan",
      imgIcon: "scan 64x64.png",
    },
    {
      label: "POS",
      icon: "pi pi-fw pi-dollar",
      to: "/pos",
      imgIcon: "point of sale 64x64.png",
    },
    {
      label: "Field Services",
      icon: "pi pi-fw pi-pencil",
      to: "/fieldservices",
      imgIcon: "field service 64x64.png",
    },
    {
      label: "Accounting",
      icon: "pi pi-fw pi-money-bill",
      to: "/accounting",
      imgIcon: "accounting 64x64.png",
    },
    // {
    //   label: "Document Control",
    //   icon: "pi pi-fw pi-file",
    //   to: "/documentControl",
    // },
    // {
    //   label: "Engineering",
    //   icon: "pi pi-fw pi-building",
    //   to: "/engineering",
    // },
    // {
    //   label: "General Ledger",
    //   icon: "pi pi-fw pi-money-bill",
    //   to: "/generalLedger",
    // },
    // {
    //   label: "Manufacturing",
    //   icon: "pi pi-fw pi-box",
    //   to: "/manufacturingPlanning",
    // },
    // {
    //   label: "Marketing and Sales",
    //   icon: "pi pi-fw pi-briefcase",
    //   to: "/marketingAndSales",
    // },
    {
      label: "Production",
      icon: "pi pi-fw pi-pencil",
      to: "/production",
      imgIcon: "project 64x64.png",
    },
    // {
    //   label: "Projects",
    //   icon: "pi pi-fw pi-folder",
    //   to: "/projects",
    // },
    // {
    //   label: "Work Flows",
    //   icon: "pi pi-fw pi-cog",
    //   to: "/workFlows",
    // },
  ],
};

export const getters: GetterTree<SideMenuState, RootState> = {
  getCollapsed(state) {
    return state.collapsed;
  },
  getWidth(state) {
    return state.sidebarWidth;
  },
  getLinks(state) {
    return state.links;
  },
  getFloatingSidebar(state) {
    return state.floatingSidebar;
  },
  getDefaultLandingPage(state) {
    return state.defaultLandingPage;
  },
};

export const mutations: MutationTree<SideMenuState> = {
  TOGGLE_SIDE_MENU(state, { collapsed, width }) {
    state.sidebarWidth = width;
    state.collapsed = collapsed;
  },
  SET_LINKS(state, links) {
    state.links = links;
  },
  SET_FLOATING_SIDEBAR(state, mode) {
    state.floatingSidebar = mode;
  },
  SET_DEFAULT_LANDING_PAGE(state, page) {
    state.defaultLandingPage = page;
  },
};

export const actions: ActionTree<SideMenuState, RootState> = {
  toggle({ commit, state }) {
    if (state.collapsed) {
      commit("TOGGLE_SIDE_MENU", { collapsed: false, width: SIDE_BAR_WIDTH });
    } else {
      commit("TOGGLE_SIDE_MENU", {
        collapsed: true,
        width: SIDE_BAR_COLLAPSED_WIDTH,
      });
    }
  },
  filterLinks({ commit, state, rootGetters }) {
    const items = state.links.filter((item) => {
      return rootGetters["session/getAccess"](item.to);
    });
    commit("SET_LINKS", items);
  },
  setFloatingSidebar({ commit }, mode) {
    commit("SET_FLOATING_SIDEBAR", mode);
  },
  setDefaultLandingPage({ commit }, page) {
    commit("SET_DEFAULT_LANDING_PAGE", page);
  },
};

export const sidemenu: Module<SideMenuState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
