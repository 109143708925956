import { AxiosInstance } from "axios";
import axios from "axios";
import store from "../store";
import ClientAPI from "./ClientAPI";

export default class InventoryService {
  private readonly client: AxiosInstance;
  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async getInventory(
    id: string,
    client: string,
    correls: string,
    cust?: string,
  ) {
    return ClientAPI.Get(`inventory/${id}`, { client, correls, cust });
  }

  async getInventoryById(
    client: string,
    id: string,
    fieldnames: string,
    correls: string,
    cust?: string,
  ) {
    return ClientAPI.Get(`inventoryById/${id}`, {
      client,
      correls,
      fieldnames,
      cust,
    });
  }

  async postItTransaction(client: string, transaction: any) {
    const params = {
      client,
    };

    return ClientAPI.Post(`transfer`, transaction, params);
  }
}
