import { Module, MutationTree, ActionTree, GetterTree } from "vuex";
import { RootState } from "@/types/state";
import CashState from "@/types/state/cash";

import CashService from "@/services/Cash";

const namespaced = true;

const cashService = new CashService(process.env.VUE_APP_ABSTRACTION_API);

export const state: CashState = {
  cash: null,
  page: 0,
  firstRow: 0,
  rangeStart: 1,
  rangeEnd: 100,
  isLoading: false,
};

export const getters: GetterTree<CashState, RootState> = {
  getCash: (state) => {
    return state.cash;
  },
  getRangeStart: (state) => {
    return state.rangeStart;
  },
  getRangeEnd: (state) => {
    return state.rangeEnd;
  },
  getFirstRow: (state) => {
    return state.firstRow;
  },
  getCurrPage: (state) => {
    return state.page;
  },
  getIsLoading: (state) => {
    return state.isLoading;
  },
  getCashLength: (state) => {
    return state.cash?.length || 0;
  },
};

export const mutations: MutationTree<CashState> = {
  SET_CASH(state, data) {
    if (state.cash != null) {
      state.cash!.push(...data);
    } else {
      state.cash = data;
    }
  },
  SET_FIRST_ROW(state, row) {
    state.firstRow = row;
  },
  SET_NEXT_PAGE(state, page) {
    state.page = page;
  },
  SET_NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  CLEAR_CASH(state) {
    state.cash = null;
  },
};

export const actions: ActionTree<CashState, RootState> = {
  fetchCash(
    { commit, dispatch, state },
    { id, cust, reg, postDateStart, postDateEnd, addCash, sortBy, sortOrder },
  ) {
    return new Promise((resolve, reject) => {
      state.isLoading = true;
      if (!addCash) {
        commit("RESET_PAGE");
      }
      cashService
        .getCash(
          id,
          cust,
          postDateStart,
          postDateEnd,
          state.rangeStart,
          state.rangeEnd,
          sortBy,
          sortOrder,
          reg,
        )
        .then((response: any) => {
          if (!addCash) {
            commit("CLEAR_CASH");
            commit("SET_FIRST_ROW", 0);
          }
          commit("SET_CASH", response.cash_items);
          commit("SET_NEXT_RANGE");
          resolve({ success: true });
        })
        .catch((error) => {
          reject({ success: false });
        })
        .finally(() => {
          state.isLoading = false;
        });
    });
  },

  setFirstRow({ commit }, row) {
    commit("SET_FIRST_ROW", row);
  },

  setNextPage({ commit }, page) {
    commit("SET_NEXT_PAGE", page);
  },
};

export const cash: Module<CashState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
