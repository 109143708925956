import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import PartState from "@/types/state/parts";
import PartsService from "@/services/PartsService";

const namespaced = true;

const partsService = new PartsService();

export const state: PartState = {
  parts: [],
  page: 1,
  firstRow: 0,
  rangeStart: 1,
  rangeEnd: 100,
};

export const getters: GetterTree<PartState, RootState> = {
  getParts: (state) => {
    return state.parts;
  },
  getStartRange: (state) => {
    return state.rangeStart;
  },
  getRangeEnd: (state) => {
    return state.rangeEnd;
  },
  getFirstRow: (state) => {
    return state.firstRow;
  },
};

export const mutations: MutationTree<PartState> = {
  SET_PARTS(state, parts) {
    if (state.parts) {
      state.parts = [...state.parts, ...parts];
    } else {
      state.parts = [...parts];
    }
  },
  SET_FIRST_ROW(state, row) {
    state.firstRow = row;
  },
  NEXT_PAGE(state, page) {
    state.page = page;
  },
  NEXT_RANGE(state) {
    state.rangeStart += 100;
    state.rangeEnd += 100;
  },
  RESET_PAGE(state) {
    state.page = 1;
    state.rangeStart = 1;
    state.rangeEnd = 100;
  },
  CLEAR_PARTS(state) {
    state.parts = [];
  },
};

export const actions: ActionTree<PartState, RootState> = {
  fetchParts({ commit, dispatch }, { Client, type, Id, isAddingParts }) {
    return new Promise((resolve, reject) => {
      if (!isAddingParts) {
        commit("RESET_PAGE");
      }
      partsService
        .getPartsRecords(Client, state.rangeStart, state.rangeEnd, type, Id)
        .then((response: any) => {
          if (!isAddingParts) {
            commit("CLEAR_PARTS");
            commit("SET_FIRST_ROW", 0);
          }
          commit("SET_PARTS", response.parts_items);
          commit("NEXT_RANGE");
          resolve({ success: true });
        })
        .catch((error: any) => {
          reject({ success: false, error: error });
        });
    });
  },
  setFirstRow({ commit }, row) {
    commit("SET_FIRST_ROW", row);
  },
};
export const parts: Module<PartState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
