import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"display":"flex","white-space":"pre-line"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Button = _resolveComponent("Button")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Toast, {
      modelValue: _ctx.notificationItems,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.notificationItems) = $event)),
      position: "bottom-right"
    }, null, 8, ["modelValue"]),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dialogNotifications, (item) => {
      return (_openBlock(), _createBlock(_component_Dialog, {
        breakpoints: { '960px': '75vw', '640px': '90vw' },
        style: { width: '350px' },
        visible: item.dialog,
        modal: "",
        header: _ctx.notificationHeader(item.type),
        closable: false,
        key: item.id
      }, {
        footer: _withCtx(() => [
          _createVNode(_component_Button, {
            label: "Close",
            onClick: ($event: any) => (_ctx.closeNotification(item)),
            autofocus: ""
          }, null, 8, ["onClick"])
        ]),
        default: _withCtx(() => [
          _createElementVNode("p", _hoisted_1, [
            _createElementVNode("i", {
              class: _normalizeClass(_ctx.getClassForNotificationType(item.type)),
              style: {"font-size":"2rem","margin-right":"1rem"}
            }, null, 2),
            _createTextVNode(" " + _toDisplayString(item.message), 1)
          ])
        ]),
        _: 2
      }, 1032, ["visible", "header"]))
    }), 128))
  ]))
}