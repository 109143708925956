import { AxiosInstance } from "axios";
import axios from "axios";
import ClientAPI from "./ClientAPI";
import store from "@/store";
import ApiService from "./ApiService";

const usersFile = "users";

export default class UsersService extends ApiService {
  constructor() {
    super(usersFile, [{ name: usersFile, keyPath: "id" }]);
  }

  async getUsers() {
    const client = store.getters["session/getClient"];
    const params = {
      client,
    };
    return ClientAPI.Get(`users`, params);
  }
}
