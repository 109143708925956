import { Module, GetterTree, MutationTree, ActionTree } from "vuex";
import { RootState } from "@/types/state";
import ShipControlState from "@/types/controls/ship";
import ControlService from "@/services/ControlService";

const namespaced = true;
const controlService = new ControlService();

export const state: ShipControlState = {
  country_items: [],
  change_date_items: [],
  ship_via_items: [],
};

export const getters: GetterTree<ShipControlState, RootState> = {
  getCountryItems: (state) => {
    return state.country_items;
  },
  getShipViaItems: (state) => {
    return state.ship_via_items;
  },
};

export const mutations: MutationTree<ShipControlState> = {
  SET_SHIP_VIA_ITEMS(state, ship_via_items) {
    state.ship_via_items = ship_via_items;
  },
  SET_COUNTRY_ITEMS(state, country_items) {
    state.country_items = country_items;
  },
  SET_CHANGE_DATE_ITEMS(state, change_date_items) {
    state.change_date_items = change_date_items;
  },
};

export const actions: ActionTree<ShipControlState, RootState> = {
  async getShipControl({ commit, state }) {
    return new Promise((resolve, reject) => {
      if (state.ship_via_items.length > 0) {
        resolve(state);
      } else {
        controlService
          .getControl("SHIP", "SHIP.CONTROL", "CONTROL", "")
          .then((response: any) => {
            if (response.length > 0) {
              commit("SET_SHIP_VIA_ITEMS", response[0].ship_via_items || []);
              commit("SET_COUNTRY_ITEMS", response[0].country_items || []);
              commit(
                "SET_CHANGE_DATE_ITEMS",
                response[0].change_date_items || [],
              );
              resolve(response[0]);
            } else {
              reject("No data returned");
            }
          })
          .catch((error) => {
            reject(error);
          });
      }
    });
  },
};

export const shipControl: Module<ShipControlState, RootState> = {
  namespaced,
  getters,
  state,
  mutations,
  actions,
};
