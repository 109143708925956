import { AxiosInstance } from "axios";
import axios from "axios";
import store from "@/store";
import ClientAPI from "./ClientAPI";

export default class EnvServices {
  private readonly client: AxiosInstance;

  constructor(serviceUrl: string) {
    this.client = axios.create({
      baseURL: serviceUrl,
      withCredentials: false,
    });
  }

  async fetchModules(): Promise<any> {
    return ClientAPI.Get("restrictedTo", {});
  }
}
